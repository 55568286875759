
import axiosInstance from './axiosInstance';
import { useRouter } from 'next/router';
const API_URL = process.env.NEXT_PUBLIC_API_SERVER_URL || 'https://api.tcom.vn';
export const fetchApi = async (url, method = 'get', body, headers) => {
    try {
        let lg = localStorage.getItem('lg');
        let opts = {
            method,
            url: `${API_URL.trim()}${url}`,
            timeout: 1 * 1000 * 60, // 1phut     
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                locale: lg
            }
        };
        if (headers) {
            opts = {
                ...opts,
                headers: {
                    ...headers,
                    [headers.key]: headers.value,
                }
            };
        }
        if (method === 'get') {
            opts.params = body;
        } else {
            opts.data = body;
        }




        let fetchdata = await axiosInstance(opts);
        if (fetchdata.data.code !== 200) {
            return fetchdata.data;
        }
        return fetchdata.data;
    } catch (error) {
        let { response } = error;
        if (response) {
            return response.data;
        }
        return error;
    }
};

export const fetchApiUpload = async (url, method = 'get', body) => {
    try {
        let opts = {
            method,
            url: `${API_URL.trim()}${url}`,
            timeout: 1 * 1000 * 60, // 1phut     
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            }
        };
        if (method === 'get') {
            opts.params = body;
        } else {
            opts.data = body;
        }
        let fetchdata = await axiosInstance(opts);
        if (fetchdata.data.code !== 200) {
            return fetchdata.data;
        }
        return fetchdata.data;
    } catch (error) {
        let { response } = error;
        if (response) {
            return response.data;
        }
        return error;
    }
};



import { useRouter } from 'next/router';
import en from '../../public/lang/en.js';
import vi from '../../public/lang/vi.js';
import jp from '../../public/lang/jp.js';

const value = key => {
  switch (key) {
    case 'jp':
      return jp;
      // eslint-disable-next-line no-unreachable
      break;
    case 'en':
      return en;
      // eslint-disable-next-line no-unreachable
      break;
    case 'vi':
      return vi;

    default:
      return vi;
      // eslint-disable-next-line no-unreachable
      break;
  }
};

const useTrans = () => {
  const { locale } = useRouter();
  // const trans = locale === 'en' ? en : vi;
  const trans = value(locale);
  return trans;
};

export default useTrans;

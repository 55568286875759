
import {
    LOAD_SERVICES
} from '../../constants/ActionTypes';
import { fetchApi } from "../../utils/api";

export async function getAllServices(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/service/get-list-service?limit=12`, "get", payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        dispatch(saveServices(response.data.rows));
        return response.data;
    } catch (error) {

        return {
            rows: [],
        };
    }
}
export async function getDetailServices(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/service/get-detail`, "post", payload);

        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {

        return {
            rows: [],
        };
    }
}

export function saveServices(payload) {
    return {
        type: LOAD_SERVICES,
        payload
    };
}

export default {
  home: {
    play_video: 'ビデオを見る',
    office_title: 'オフィス写真',
    office_video: 'オフィス写真',
    title: 'EYEFIRE',
    description_a: 'Phát triển sản phẩm dựa trên nền tảng Salesforce',
    description_b: 'Các giải pháp E-Commerce Website',
    description_c: 'Tư vấn và phát triển phần mềm',
    description_d: 'Dịch vụ offshore hàng đầu Việt Nam',
    sub_description_a:
      'Đội ngũ kỹ sư nhiều kinh nghiệm, có khả năng phát triển các sản phẩm, dịch vụ dựa trên nền tảng Salesforce, một nền tảng CRM tốt nhất hiện tại',
    sub_description_b:
      'Tư vấn, thiết kế và phát triển các hệ thống website thương mại điện tử đáp ứng mọi quy mô của doanh nghiệp',
    sub_description_c:
      'Tư vấn và phát triển giải pháp, sản phẩm phần mềm dựa trên công nghệ Trí tuệ nhân tạo A.I, Blockchain, AR/VR, Livestreaming cho các tổ chức, doanh nghiệp trong và quốc tế',
    sub_description_d:
      'Đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới. Quy trình phát triển phần mềm chuyên nghiệp, quy trình kiểm soát chất lượng nghiêm ngặt. Chúng tôi đã, đang và sẽ là đối tác của các khách hàng lớn trên toàn thế giới.',
    integrated_title1: 'On Premise',
    integrated_title2: 'Cloud Service',
    integrated_title3: 'Tích hợp',
    integrated_content1:
      'Triển khai trọn gói trên hạ tầng của đối tác, khách hàng',
    integrated_content2:
      'Sử dụng hạ tầng cloud server của EYEFIRE, triển khai nhanh chóng',
    integrated_content3:
      'MobileSDK, ServerSDK, APIs đáp ứng hầu hết các nhu cầu tích hợp',
    hotTech: 'Công nghệ nổi bật',
    solutions: 'Các giải pháp',
    solutions_des:
      'Giải pháp toàn diện, giải quyết các bài toán thực tế.</br> Có thể tuỳ biến đáp ứng mọi nhu cầu',
    product: 'SẢN PHẨM',
    product_sub:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
    facesdk:
      "<p>Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng,<br class='item-pc'/> các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK</p>",
    news: 'TIN TỨC',
    new_des:
      'Thông tin công nghệ, sản phẩm của EYEFIRE. Các sự kiện, hoạt động hợp tác, các thông báo và <br/> nhiều thông tin khác',

    field: 'CÁC LĨNH VỰC',
    field_sub: 'Công nghệ của EYEFIRE có thể ứng dụng trong mọi lĩnh vực',
    customer: 'Khách hàng và đối tác',
    customer_des:
      'EYEFIRE tự hào khi là đối tác của các doanh nghiệp, tổ chức hàng đầu',
    start: 'TÍCH HỢP VÀ TRIỂN KHAI',
    start_des:
      'Công nghệ nhận diện đa nền tảng, customize theo nhu cầu, linh động các hình thức triển khai',
    contact: 'Liên hệ để được tư vấn',
    slider_text:
      'TCOM Software ソフトウェアは、常に革新を続ける高度な資格を持つ技術エンジニアのチームを擁しています。プロフェッショナルなソフトウェア開発プロセス、厳格な品質管理プロセス。私たちはこれまでも、そしてこれからも、世界中の主要顧客のパートナーであり続けます。',
    slider_text_icon_a: '高いコストパフォーマンス',
    slider_text_icon_b: '高品質',
    slider_text_icon_c: '即時対応',
    our_service_title: '私たちが提供するサービス',
    sub_our_service_title1:
      '強力なソフトウェア ソリューションを提供します。世界中の顧客にとって拡張性と信頼性に優れています。 ',
    sub_our_service_title2: '',
    sub_our_service_title:
      '強力なソフトウェア ソリューションを提供します。世界中の顧客にとって拡張性と信頼性に優れています。 có thể mở rộng và đáng tin cậy cho khách hàng trên toàn cầu.',
    our_service_item_title_a: 'Web Development',
    our_service_item_title_b: 'Mobile App Development',
    our_service_item_title_c: 'Video Solutions',
    our_service_item_title_d: 'Realtime Solutions',
    our_service_item_title_e: 'A.I Reseach & Development',
    our_service_item_title_f: 'Blockchain Development',
    our_service_item_title_g: 'QA & Testing',
    our_service_item_title_h: 'Maintenance & IT Managed',
    our_service_item_title_i: 'UI/UX Design',
    our_service_item_des_a:
      'Đội ngũ nhân sự với kinh nghiệm nhiều năm tư vấn, thiết kế, phát triển các sản phẩm trên nền tảng web.',
    our_service_item_des_b_1: 'Native Mobile App Development Hybrid',
    our_service_item_des_b_2: 'Mobile App Development Mobile',
    our_service_item_des_b_3: 'Application Re-Engineering',
    our_service_item_des_c:
      'Tư vấn giải pháp, phát triển các hệ thống xử lý, quản lý, phân phối video/live video với hiệu năng cao, chịu tải lớn, đáp ứng hàng triệu người dùng.',
    our_service_item_des_d:
      'Tư vấn, phát triển các hệ thống tương tác thời gian thực đa nền tảng với công nghệ mới và mạnh mẽ.',
    our_service_item_des_e:
      'Phát triển các giải pháp trí tuệ nhân tạo, dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, vật thể.',
    our_service_item_des_f:
      'Đội ngũ TCOM với nhiều chuyên gia trong lĩnh vực Blockchain, đáp ứng các nhu cầu phát triển sản phẩm, ứng dụng cho doanh nghiệp của bạn.',
    our_service_item_des_g:
      'Đội ngũ QA/QC của TCOM có nhiều năm kinh nghiệm trong kiểm thử phần mềm.',
    our_service_item_des_h:
      'Chúng tôi cung cấp dịch vụ offshore nhằm mang tới cho đối tác, khách hàng sản phẩm chất lượng với giá cả cạnh tranh nhất.',
    our_service_item_des_i:
      'Đội ngũ designer của TCOM có nhiều năm kinh nghiệm trong tư vấn, thiết kế UI/UX sản phẩm phần mềm.',
    our_service_methods: '協力の方法 TCOM Software',
    sub_our_service_methods:
      '強力なソフトウェアソリューションを提供し、世界中のお客様にとってスケーラブルで信頼性の高いものです。',
    project_based_a: 'プロジェクトごとに契約を結ぶオフショア形態です。',
    project_based_b:
      '当社内に専属の開発チームを編成し、管理、運用することができます。プロジェクト遂行のための安定したマンパワーを確保します。',
    procedure_title: '契約までの流れ',
    procedure_title_sub: '',
    procedure_step_1: 'ご相談・ヒアリング',
    procedure_step_2: 'NDA',
    procedure_step_3: 'ご提案・御見積',
    procedure_step_4: '契約締結',
    procedure_step_5: '業務開始',
    procedure_step_6: '納品',
    procedure_step_sub_1: '',
    procedure_step_sub_2: '',
    procedure_step_sub_3: '',
    procedure_step_sub_4: '',
    procedure_step_sub_5: '',
    procedure_step_sub_6: '',
    project_title: '傑出したプロジェクト',
    project_sub_title:
      '私たちが成功裏に実装し、顧客を満足させたいくつかの優れたプロジェクト',
    project_text:
      'Ứng dụng du lịch được cá nhân hóa, tất cả trong một, để book vé, khách sạn, nhà hàng, các tour du lịch trải nghiệm.',
    achievement_title: '実績',
    achievement_text:
      '私たちが成功裏に実装し、顧客を満足させたいくつかの優れたプロジェクト.',
    achievement_project: 'Dự án',
    achievement_customer: 'クライアント',
    achievement_engineer: 'エンジニア',
    achievement_expert: '専門家 ',
    title: 'TCOM Software',
    desc: 'CUNG CẤP DỊCH VỤ OFFSHORE',
    feature_project: '傑出したプロジェクト',
    feature_project_description:
      '私たちが成功裏に実装し、顧客を満足させたいくつかの優れたプロジェクト',
    language_tech: '技術・言語',
    language_tech_sub:
      'お客様により良い製品をご提供するために、当社は常に社員の能力開発に努めています。',
  },
  about: {
    our_title: 'VỀ CHÚNG TÔI',
    our_text:
      'TCOM Japan, thành lập tháng 05/2021, có trụ sở tại Tokyo. TCOM Japan chuyên cung cấp dịch vụ offshore, tư vấn hệ thống IT tại thị trường Nhật Bản. TCOM Japan có công ty mẹ là công ty TCOM tại Việt Nam - công ty công nghệ đa lĩnh vực, với đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới và quy trình phát triển phần mềm chuyên nghiệp, quy trình kiểm soát chất lượng nghiêm ngặt.',
    our_sub:
      'TCOM Japan đã, đang và sẽ là đối tác của các khách hàng lớn tại Nhật Bản',
    us_title: 'ĐỘI NGŨ CỦA CHÚNG TÔI',
    images: 'MỘT SỐ HÌNH ẢNH',
    profile_title: 'Company Profile',
    profile_text1:
      'Chúng tôi cung cấp dịch vụ offshore, tư vấn hệ thống IT và phát triển phần mềm. Các công nghệ nổi bật mà chúng tôi có thế mạnh như A.I, blockchain, AR/VR, video livestreaming.',
    download: 'Tải ngay Profile',
    achievement_title: 'THÀNH TÍCH',
    achievement_text:
      'Luôn mở rộng quy mô, nâng cao trình độ đội ngũ, quy trình kiểm soát chất lượng nghiêm ngặt, TCOM Japan đã và đang là đối tác tin cậy của các doanh nghiệp trong và ngoài nước.',
    achievement_project: '計画',
    achievement_customer: 'クライアント',
    achievement_engineer: 'エンジニア',
    achievement_expert: '専門家 ',
    title: 'TCOM Software',
    desc: 'CUNG CẤP DỊCH VỤ OFFSHORE',
    customer: 'クライアント',
    customer_text: `世界中からお客様がいらっしゃいます. 献身と責任を持って創造的で、私たちはパートナーに最高品質の製品サービスと満足を提供します`,
    customer_text1: ``,
  },
  layout: {
    vn: 'Viet Nam',
    el: 'English',
    jp: 'Japan',
    about: 'Về chúng tôi',
    service: 'サービス',
    home: 'ホームページ',
    technology: 'Công nghệ',
    project: '開発実績',
    product: 'Sản phẩm',
    solution: 'ソリューション',
    recruitment: 'Tuyển dụng',
    sdk: 'SDK',
    job: 'Tuyển dụng',
    news: 'Tin tức',
    contact: 'お問い合わせ',
    slogan:
      'Tiên phong trong các sản phẩm và giải <br class="item-sp"> pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh.',
    office: 'OFFICE',
    menu: 'Menu',
    adrHn: 'TRỤ SỞ',
    adrHcm: 'CHI NHÁNH HỒ CHÍ MINH',
    adrJp: 'CHI NHÁNH NHẬT BẢN',
    address1:
      'Tầng 3 tòa nhà Fafim A, Số 19 Nguyễn Trãi,</br> Phường Khương Trung, Quận Thanh Xuân, TP Hà Nội',
    address2:
      'Tầng 2, Toà nhà văn phòng, Số 102 Nguyễn Đình Chính,</br> Phường 15, Quận Phú Nhuận, TP Hồ Chí Minh',
    address3: '〒190-0023 <br />東京都立川市柴崎町3-8-5立川NXビル5F',
    rule1: 'Điều khoản dịch vụ',
    rule2: 'Chính sách bảo mật',
    keep: '連絡を取り合う',
    copyright: 'Copyright © 2021EYEFIRE Corporation. All rights reserved.',
    contact_footer: 'LIÊN HỆ',
    content_contact_footer1:
      'Hãy gửi cho chúng tôi những thắc mắc, góp ý hoặc đề nghị hợp tác của bạn.',
    content_contact_footer2: 'Chúng tôi sẽ phản hồi trong thời gian sớm nhất!',
    button_contact: 'お問い合わせ',
    button_detail: 'Xem chi tiết',
    btn_send: '情報を送信',
    title_contact: 'お問い合わせ',
    content_contact1:
      '情報を送信 để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh.<br /> Vui lòng gửi email cho chúng tôi bất cứ lúc nào.',
    content_contact2: 'Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
    footer_title: 'TCOM Software',
  },
  off_shore: {
    text: 'オフショアサービス',
    offshore_d:
      'TCOM Software ソフトウェア オフショア センターは、日本のお客様の信頼できるパートナーです。当社は、パートナーや顧客に高品質の製品を最も競争力のある価格で提供するオフショア サービスを提供しています。',
    title: 'オフショアサービス',
    title_dis: 'TCOM Software',
    introduce:
      'オフショアとは一般的に、コスト削減を目的として、人件費や物価の安い海外企業に業務の一部を委託することです。',
    benefit: 'オフショアのメリットは?',
    benefit_description:
      '内部リソースでは問題解決ができない→人員や技術面で、実施・処理に時間を要する問題はオフショア移行で解決します。コスト削減をしたい→オフショアは、自社ですべて手がける場合、または自国内で人員を雇用する場合に比べて、大幅にコストを削減できます。コア業務に集中したい→優先順位の低い仕事をオフショアし、価値を生み出すコア業務に自社の十分なリソースを投入することが可能になります。スピードを重視したい→多くのスタートアップ企業は、迅速かつ安価なサービスを求めてオフショアに移行しています。',
    content_title:
      '高品質・高コストパフォーマンスのオフショアサービスを提供しています',
    awards: '実績',
    why_choose: 'Lý do bạn nên chọn TCOM Japan',
    reason_a: 'Chi phí hợp lý, tiết kiệm cho bạn 60%',
    reason_b: 'Có quản lý chất lượng người Nhật ',
    reason_c: 'Đội ngũ kỹ sư trình độ cao, quy trình chuyên nghiệp',
    method: '協力の方法',
    method_1: 'プロジェクトベース（請負型)',
    method_des_1:
      'プロジェクトごとに契約を結ぶオフショア形態です。当社が、顧客から要望をうかがい、要件定義、設計、開発、テスト、リリースなど、プロジェクトの開発工程におけるすべての活動および開発チームを管理します。',
    method_2: '専属チームの編成（ラボ型)',
    method_des_2:
      '当社内に専属の開発チームを編成し、管理、運用することができます。プロジェクト遂行のための安定したマンパワーを確保します。',
    pattern_1: 'パターン①',
    pattern_des_1: '顧客とブリッジSE（BrSE） が直接連携して作業を行います。',
    pattern_2: 'パターン②',
    pattern_des_2:
      'ブリッジSEが顧客側に出向き、ベトナム側に専属の開発担当チームを立ち上げます。',
    service: '私たちが提供するサービス',
    procedure: '契約までの流れ',
    procedure_des: '',
    procedure_1: 'ご相談・ヒアリング',
    procedure_des_1: '',
    slogan1:
      ' 高品質・高コストパフォーマンスのオフショアサービスを提供しています ',
    slogan_des_1: '高いコストパフォーマンス',
    slogan_des_3: '高品質',
    slogan_des_5: '即時対応',
  },
  project: {
    btn_back: '戻る',
    feature_project: '注目のプロジェクト',
    feature_project_description: `強力なソフトウェア ソリューションを提供します。世界中の顧客にとって拡張性と信頼性に優れています。 `,
    feature_project_description1: ``,
    feature_project_description_3: `<p className='ndaj-dt'>強力なソフトウェアソリューションを提供し、世界 </br>中のお客様にとってスケーラブルで信頼性の高いものです。</p>`,
    feature_project_description_4: `強力なソフトウェアソリューションを提供し、世界中のお客様にとってスケーラブルで信頼性の高いものです。`,
    search: '探す',
    field: '分野',
    language: '言語',
    tech: 'テクノロジー',
    back_home: 'QUAY VỀ TRANG CHỦ',
    load_more: 'もっと見る',
    platform: '財団',
    scale: '規模',
    back: '戻る',
  },
  product: {
    product: 'SẢN PHẨM',
    product_des:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
  },
  news: {
    title_featured: 'TIN TỨC NỔI BẬT',
    featured_description:
      'Cùng điểm qua những tin tức đáng chú ý trong thời gian qua từ TCOM Software nhé',
    title: 'Các tin tức mới nhất',
    news: 'TIN TỨC',
    new_des:
      'Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh',
    new_hot: 'TIN TỨC NỔI BẬT',
    new_hostlist:
      'Cùng điểm qua những tin tức đáng chú ý trong thời gian qua từ Eyefire nhé',
    list_hot: 'Các tin tức mới nhất',
    search: 'Tìm kiếm',
    relate: 'Tin liên quan',
    description:
      'EYEFIRE - Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
  },
  solution: {
    title_solution: 'ハイライト ソリューション',
    title_solution_des:
      'TCOM Software ソフトウェアは、多くの包括的なテクノロジー プラットフォームとソリューションを開発し、企業や組織のデジタル変革プロセスを促進してきました。',
    content_solution:
      '<p>Giải pháp toàn diện, giải quyết các bài toán thực tế. Có thể tuỳ biến đáp ứng mọi nhu cầu</p>',
    our_service_sub1: '',
    our_service_sub2: '',
  },

  text_button: {
    read_more: 'もっと見る',
    read_more_b: 'もっと見る',
    detail: 'Xem chi tiết',
    more: 'Tìm hiểu thêm',
    back: '戻る',
  },

  contact: {
    send: 'お問い合わせ',
    title: 'お問い合わせ',
    description: ` <p className='desc'>お問い合わせはメール・お電話にてお受けしております。
お送りいただいた内容を確認のうえ、ご返答させていただきます。

</p>`,
    description_text: `ご相談、お見積等について、こちらにお問い合わせください。`,
    description_mb: ` <p className='desc'>お問い合わせはメール・お電話にてお受けしております。
お送りいただいた内容を確認のうえ、ご返答させていただきます。

</p>`,
    tcom_vn: 'Tcom',
    contacttitle: '今すぐ連絡する',
    name: '氏名',
    phone: 'メールアドレス',
    tieu_de: '電話番号',
    purpose: 'タイトル',
    content: 'Nội dung',
    submit: '情報を送信',
    member: 'HỆ THỐNG THÀNH VIÊN CỦA CHÚNG TÔI',
    subTitle:
      'TCOM không ngừng lớn mạnh, chúng tôi đem đến cho bạn những giải pháp, công nghệ tiện ích cuộc sống trong thời đại 4.0',
    option_a: 'Mục đích liên hệ',
    option_b: 'Báo giá',
    option_c: 'Yêu cầu tư vấn',
    option_d: 'Hợp tác',
    option_e: 'Tuyển dụng',
    option_f: 'Khác',
    title_l_a: '本部 (ハノイ)',
    title_l_d_a:
      '3F, Fafim A Building, 19 Nguyen Trai Street, Thanh Xuan District, Ha Noi City, Viet Nam',

    title_l_b: '開発拠点（ホーチミン市）',
    title_l_d_b:
      '2F, Office Building 102 Nguyen Dinh Chinh Street, Ward 15, Phu Nhuan District, Ho Chi Minh City, Viet Nam',

    title_l_c: 'TCOM Software',
    title_l_d_c: '〒190-0023 東京都立川市柴崎町3-8-5立川NXビル5F',
    location_a: '本部 (ハノイ)',
    location_a_text:
      '3F, Fafim A Building, 19 Nguyen Trai Street, Thanh Xuan District, Ha Noi City, Viet Nam',

    location_b: '開発拠点（ホーチミン市）',
    location_b_text:
      '2F, Office Building 102 Nguyen Dinh Chinh Street, Ward 15, Phu Nhuan District, Ho Chi Minh City, Viet Nam',

    location_c: 'TCOM Japan',
    location_c_text: '〒190-0023 東京都立川市柴崎町3-8-5',
    introduction: '連絡を取り合う',
    href_a: 'サービス',
    href_b: 'ソリューション',
    href_c: '開発実績',

    services: 'サービス',
    form_mail: 'メールアドレス',
    form_name: '氏名',
    form_phone: 'メールアドレス',
    form_subject: '電話番号',
    form_purpose: '連絡の目的',
    form_quotation: 'リクルート',
    form_request: '見積もり',
    form_cooperation: 'カウンセリングを依頼する',
    form_recruitment: '協力する',
    form_orther: '他の',
    form_message: 'お問い合わせ内容',
    form_file: '添付ファイル',
  },

  error: {
    title: 'ページが見つかりません',
    button: 'ホームページ へ戻る',
    name: '氏名を入力してください',
    mail: 'メールアドレスを入力してください',
    mail_format: 'メールアドレスを正しく入力してください',
    phone: '電話番号を入力してください',
    phone_format: '電話番号を正しく入力してください',
    subject: 'タイトルを入力してください',
    need: '連絡の目的を選択してください',
    message: 'お問い合わせ内容を入力してください',
    file: '添付ファイルを選択してください',
  },
  seo: {
    home: {
      title: 'Trang chủ',
      description:
        '我々はベトナムのトップソフトウェア開発会社です。世界中の顧客に最高の品質、最適なコストでオフショアサービスを提供しております。',
    },
    technology: {
      title: 'Công nghệ',
      description:
        'Công nghệ nhận diện khuôn mặt, nhận diện phương tiện giao thông, nhận diện hành vi bất thường, phát hiện sản phẩm lỗi, phát hiện chuyển động, phát hiện xâm nhập, hàng rào ảo, AIoT camera',
    },
    solution: {
      title: '優れたソリューション',
      description:
        'TCOM Software は、企業や組織のデジタルトランスフォーメーションを推進するために、多くの包括的なテクノロジープラットフォームとソリューションを開発してきました。 ',
    },
    project: {
      title: '注目のプロジェクト',
      description:
        'TCOM Softwareは、日本のお客様を満足させるために、ほぼすべての分野で多くの大型プロジェクトを実施してきました。',
    },
    news: {
      title: 'Tin tức',
      description:
        'Những tin tức, sự kiện nổi bật về hoạt động hợp tác kinh doanh, cũng như các hoạt động nội bộ của EYEFIRE',
    },
    sdk: {
      title: 'SDK',
      description:
        'Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng, các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK đa nền tảng bao gồm iOS SDK, Android SDK, Server SDK',
    },
    contact: {
      title: 'お問い合わせ',
      description:
        'お問い合わせはメール・お電話にてお受けしております。お送りいただいた内容を確認のうえ、ご返答させていただきます。',
    },
  },
  service: {
    our_service: 'サービス内容',
    field: '強み',
    field_description: 'サービス提供エリア',
    language_code: 'プログラミング言語',
    tech: 'テクノロジー',
    field_second: '分野 サービス提供エリア',
    our_service_sub1: 'サービス',
    our_service_sub2: '',
    tool: 'ツール',
  },
  detail_service: {
    detail_service_a: '契約までの流れ',
    detail_service_b: '',
  },
  contact_a: 'CONTACT',
};

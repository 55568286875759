export default {
  home: {
    play_video: 'Watch now',
    office_title: 'Office',
    office_video: 'Image & Video',
    title: 'EYEFIRE',
    description_a: 'Phát triển sản phẩm dựa trên nền tảng Salesforce',
    description_b: 'Các giải pháp E-Commerce Website',
    description_c: 'Tư vấn và phát triển phần mềm',
    description_d: 'Dịch vụ offshore hàng đầu Việt Nam',
    sub_description_a:
      'Đội ngũ kỹ sư nhiều kinh nghiệm, có khả năng phát triển các sản phẩm, dịch vụ dựa trên nền tảng Salesforce, một nền tảng CRM tốt nhất hiện tại',
    sub_description_b:
      'Tư vấn, thiết kế và phát triển các hệ thống website thương mại điện tử đáp ứng mọi quy mô của doanh nghiệp',
    sub_description_c:
      'Tư vấn và phát triển giải pháp, sản phẩm phần mềm dựa trên công nghệ Trí tuệ nhân tạo A.I, Blockchain, AR/VR, Livestreaming cho các tổ chức, doanh nghiệp trong và quốc tế',
    sub_description_d:
      'Đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới. Quy trình phát triển phần mềm chuyên nghiệp, quy trình kiểm soát chất lượng nghiêm ngặt. Chúng tôi đã, đang và sẽ là đối tác của các khách hàng lớn trên toàn thế giới.',
    integrated_title1: 'On Premise',
    integrated_title2: 'Cloud Service',
    integrated_title3: 'Tích hợp',
    integrated_content1:
      'Triển khai trọn gói trên hạ tầng của đối tác, khách hàng',
    integrated_content2:
      'Sử dụng hạ tầng cloud server của EYEFIRE, triển khai nhanh chóng',
    integrated_content3:
      'MobileSDK, ServerSDK, APIs đáp ứng hầu hết các nhu cầu tích hợp',
    hotTech: 'Công nghệ nổi bật',
    solutions: 'Các giải pháp',
    solutions_des:
      'Giải pháp toàn diện, giải quyết các bài toán thực tế.</br> Có thể tuỳ biến đáp ứng mọi nhu cầu',
    product: 'SẢN PHẨM',
    product_sub:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
    facesdk:
      "<p>Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng,<br class='item-pc'/> các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK</p>",
    news: 'TIN TỨC',
    new_des:
      'Thông tin công nghệ, sản phẩm của EYEFIRE. Các sự kiện, hoạt động hợp tác, các thông báo và <br/> nhiều thông tin khác',

    field: 'CÁC LĨNH VỰC',
    field_sub: 'Công nghệ của EYEFIRE có thể ứng dụng trong mọi lĩnh vực',
    customer: 'Khách hàng và đối tác',
    customer_des:
      'EYEFIRE tự hào khi là đối tác của các doanh nghiệp, tổ chức hàng đầu',
    start: 'TÍCH HỢP VÀ TRIỂN KHAI',
    start_des:
      'Công nghệ nhận diện đa nền tảng, customize theo nhu cầu, linh động các hình thức triển khai',
    contact: 'Liên hệ để được tư vấn',
    slider_text:
      'TCOM Software offering top-notch IT outsourcing services. We are committed to delivering the best technology solutions with innovation, agility and a strong one-team spirit',
    slider_text_icon_a: 'Optimal cost',
    slider_text_icon_b: 'Best quality',
    slider_text_icon_c: 'Fastest Deployment',
    our_service_title: 'Our Services',
    sub_our_service_title1:
      'TCOM Software is a leading technology and software services company, we supply customers of',
    sub_our_service_title2:
      'all sizes throughout the world with software solutions that are powerful, scalable, and reliable',
    sub_our_service_title:
      'TCOM Software is a leading technology and software services company, we supply customers of all sizes throughout the world with software solutions that are powerful, scalable, and reliable',
    our_service_item_title_a: 'Web Development',
    our_service_item_title_b: 'Mobile App Development',
    our_service_item_title_c: 'Video Solutions',
    our_service_item_title_d: 'Realtime Solutions',
    our_service_item_title_e: 'A.I Reseach & Development',
    our_service_item_title_f: 'Blockchain Development',
    our_service_item_title_g: 'QA & Testing',
    our_service_item_title_h: 'Maintenance & IT Managed',
    our_service_item_title_i: 'UI/UX Design',
    our_service_item_des_a:
      'Đội ngũ nhân sự với kinh nghiệm nhiều năm tư vấn, thiết kế, phát triển các sản phẩm trên nền tảng web.',
    our_service_item_des_b_1: 'Native Mobile App Development Hybrid',
    our_service_item_des_b_2: 'Mobile App Development Mobile',
    our_service_item_des_b_3: 'Application Re-Engineering',
    our_service_item_des_c:
      'Tư vấn giải pháp, phát triển các hệ thống xử lý, quản lý, phân phối video/live video với hiệu năng cao, chịu tải lớn, đáp ứng hàng triệu người dùng.',
    our_service_item_des_d:
      'Tư vấn, phát triển các hệ thống tương tác thời gian thực đa nền tảng với công nghệ mới và mạnh mẽ.',
    our_service_item_des_e:
      'Phát triển các giải pháp trí tuệ nhân tạo, dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, vật thể.',
    our_service_item_des_f:
      'Đội ngũ TCOM Software với nhiều chuyên gia trong lĩnh vực Blockchain, đáp ứng các nhu cầu phát triển sản phẩm, ứng dụng cho doanh nghiệp của bạn.',
    our_service_item_des_g:
      'Đội ngũ QA/QC của TCOM Software có nhiều năm kinh nghiệm trong kiểm thử phần mềm.',
    our_service_item_des_h:
      'Chúng tôi cung cấp dịch vụ offshore nhằm mang tới cho đối tác, khách hàng sản phẩm chất lượng với giá cả cạnh tranh nhất.',
    our_service_item_des_i:
      'Đội ngũ designer của TCOM Software có nhiều năm kinh nghiệm trong tư vấn, thiết kế UI/UX sản phẩm phần mềm.',
    our_service_methods: 'ENGAGEMENT METHODS',
    sub_our_service_methods:
      'We provide powerful software solutions, scalable and reliable for customers globally.',
    project_based_a:
      'This is an offshore form where contracts are signed for each project.',
    project_based_b:
      'Customers will build, manage and operate a separate team on demand, located at TCOM Software.',
    procedure_title: 'Working process',
    procedure_title_sub: 'Simple, clear, and easy-to-collaborate working steps',
    procedure_step_1: 'Information gathering',
    procedure_step_2: 'Design',
    procedure_step_3: 'Program',
    procedure_step_4: 'Testing',
    procedure_step_5: 'Deployment',
    procedure_step_6: 'Evaluation',
    procedure_step_sub_1:
      'Exchange of ideas, advice, research and clarification of requirements',
    procedure_step_sub_2:
      'System architecture design, interface sketching, UI/UX design',
    procedure_step_sub_3: 'Functions programming',
    procedure_step_sub_4: 'Quality management, software testing, bug fixing',
    procedure_step_sub_5: 'Deployment of test run, beta run and production run',
    procedure_step_sub_6: 'Evaluation of quality, performance',
    project_title: 'Outstanding Projects',
    project_sub_title:
      'Here are some of our noteworthy projects to the delight of our clients',
    project_text:
      'Ứng dụng du lịch được cá nhân hóa, tất cả trong một, để book vé, khách sạn, nhà hàng, các tour du lịch trải nghiệm.',
    achievement_title: 'Outstanding Achievement',
    achievement_text:
      'Here are some of our noteworthy projects to the delight of our clients.',
    achievement_project: 'Projects',
    achievement_customer: 'Customers',
    achievement_engineer: 'Engineers',
    achievement_expert: 'Experts',
    title: 'TCOM Software',
    desc: 'CUNG CẤP DỊCH VỤ OFFSHORE',
    feature_project: 'Outstanding Projects',
    feature_project_description:
      'Here are some of our noteworthy projects to the delight of our clients',
    language_tech: 'Technologies',
    language_tech_sub:
      'We continuously update and expand our technology capacity, in order to better meet the quality of products for customers.',
  },
  about: {
    our_title: 'VỀ CHÚNG TÔI',
    our_text:
      'TCOM Japan, thành lập tháng 05/2021, có trụ sở tại Tokyo. TCOM Japan chuyên cung cấp dịch vụ offshore, tư vấn hệ thống IT tại thị trường Nhật Bản. TCOM Japan có công ty mẹ là công ty TCOM tại Việt Nam - công ty công nghệ đa lĩnh vực, với đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới và quy trình phát triển phần mềm chuyên nghiệp, quy trình kiểm soát chất lượng nghiêm ngặt.',
    our_sub:
      'TCOM Japan đã, đang và sẽ là đối tác của các khách hàng lớn tại Nhật Bản',
    us_title: 'ĐỘI NGŨ CỦA CHÚNG TÔI',
    images: 'MỘT SỐ HÌNH ẢNH',
    profile_title: 'Company Profile',
    profile_text1:
      'Chúng tôi cung cấp dịch vụ offshore, tư vấn hệ thống IT và phát triển phần mềm. Các công nghệ nổi bật mà chúng tôi có thế mạnh như A.I, blockchain, AR/VR, video livestreaming.',
    download: 'Tải ngay Profile',
    achievement_title: 'THÀNH TÍCH',
    achievement_text:
      'Luôn mở rộng quy mô, nâng cao trình độ đội ngũ, quy trình kiểm soát chất lượng nghiêm ngặt, TCOM Japan đã và đang là đối tác tin cậy của các doanh nghiệp trong và ngoài nước.',
    achievement_project: 'Projects',
    achievement_customer: 'Customers',
    achievement_engineer: 'Engineers',
    achievement_expert: 'Experts',
    title: 'TCOM Software',
    desc: 'CUNG CẤP DỊCH VỤ OFFSHORE',
    customer: 'Our Customers',
    customer_text: `Worldwide, we work with major corporations. Inspiring trust and delight among our clientele is our top priority to this end, we apply ourselves with dedication, responsibility, and creativity.`,
    customer_text1: ``,
  },
  layout: {
    vn: 'Viet Nam',
    el: 'English',
    jp: 'Japan',
    about: 'About us',
    service: 'Our Services',
    home: 'Home',
    technology: 'Công nghệ',
    project: 'Projects',
    product: 'Products',
    solution: 'Our Solutions ',
    recruitment: 'Tuyển dụng',
    sdk: 'SDK',
    job: 'Careers',
    news: 'News',
    contact: 'Contact Us',
    slogan:
      'Tiên phong trong các sản phẩm và giải <br class="item-sp"> pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh.',
    office: 'OFFICE',
    menu: 'Menu',
    adrHn: 'TRỤ SỞ',
    adrHcm: 'CHI NHÁNH HỒ CHÍ MINH',
    adrJp: 'CHI NHÁNH NHẬT BẢN',
    address1:
      'Tầng 3 tòa nhà Fafim A, Số 19 Nguyễn Trãi,</br> Phường Khương Trung, Quận Thanh Xuân, TP Hà Nội',
    address2:
      'Tầng 2, Toà nhà văn phòng, Số 102 Nguyễn Đình Chính,</br> Phường 15, Quận Phú Nhuận, TP Hồ Chí Minh',
    address3: '〒190-0023 <br />東京都立川市柴崎町3-8-5立川NXビル5F',
    rule1: 'Điều khoản dịch vụ',
    rule2: 'Chính sách bảo mật',
    keep: 'KEEP IN TOUCH',
    copyright: 'Copyright © 2021EYEFIRE Corporation. All rights reserved.',
    contact_footer: 'LIÊN HỆ',
    content_contact_footer1:
      'Hãy gửi cho chúng tôi những thắc mắc, góp ý hoặc đề nghị hợp tác của bạn.',
    content_contact_footer2: 'Chúng tôi sẽ phản hồi trong thời gian sớm nhất!',
    button_contact: 'CONTACT US',
    button_detail: 'Xem chi tiết',
    btn_send: 'Gửi thông tin',
    title_contact: 'Để lại thông tin liên hệ',
    content_contact1:
      'Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh.<br /> Vui lòng gửi email cho chúng tôi bất cứ lúc nào.',
    content_contact2: 'Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
    footer_title: 'TCOM',
  },
  off_shore: {
    text: 'Offshore',
    offshore_d:
      'TCOM Software trung tâm offshore, đối tác tin cậy của các khách hàng Nhật. Chúng tôi cung cấp dịch vụ offshore nhằm mang tới cho đối tác, khách hàng sản phẩm chất lượng với giá cả cạnh tranh nhất.',
    title: 'OFFSHORE SERVICE',
    title_dis: 'TCOM Software',
    introduce:
      'Offshore software development refers to contracting out the software development process to a third party in an overseas country. Companies that use offshore software outsourcing generally don’t have an in-house team with the necessary skills or headcount to complete their projects. Instead, these companies hire offshore developers with the right knowledge and experience.',
    benefit: 'WHAT DOES OFFSHORE BRING TO CUSTOMERS?',
    benefit_description:
      'Offshore software development offers significant benefits for businesses, including cost savings, access to a global talent pool, scalability, faster time to market, focus on core competencies, enhanced quality and expertise, and risk mitigation. It allows companies to leverage lower labor costs, tap into diverse skills and expertise, quickly scale resources, expedite development with round-the-clock work, focus on strategic initiatives, access specialized knowledge, ensure high-quality deliverables, and mitigate project risks. However, effective communication and project management are essential to overcome challenges such as communication barriers and cultural differences.',
    content_title:
      'We provide the best quality Offshore service, the most optimal cost',
    awards: 'Outstanding Achievement',
    why_choose: 'Lý do bạn nên chọn TCOM Japan',
    reason_a: 'Chi phí hợp lý, tiết kiệm cho bạn 60%',
    reason_b: 'Có quản lý chất lượng người Nhật ',
    reason_c: 'Đội ngũ kỹ sư trình độ cao, quy trình chuyên nghiệp',
    method: 'ENGAGEMENT METHODS',
    method_1: 'Project based',
    method_des_1:
      'This is an offshore form where contracts are signed for each project. TCOM Software will manage the team and all activities in the project development process including description documents from customers, technical architecture, design, programming, testing, and product deployment.',
    method_2: 'Dedicated team (labor)',
    method_des_2:
      'Customers will build, manage and operate a separate team on demand, located at TCOM Software. Labor ensures stable human resources for the project.',
    pattern_1: 'Pattern 1',
    pattern_des_1:
      'Customers communicate directly with the team through BrSE at TCOM Software',
    pattern_2: 'Pattern 2',
    pattern_des_2: `TCOM's BrSE works at the customer's company, and the implementation team is established at TCOM Software`,
    service: 'OUR SERVICES',
    procedure: 'Working process',
    procedure_des: 'Simple, clear, and easy-to-collaborate working steps',
    procedure_1: 'Information gathering',
    procedure_des_1: '',
    slogan1:
      ' We provide the best quality Offshore service, the most optimal cost ',
    slogan_des_1: 'Optimal cost',
    slogan_des_3: 'Best quality',
    slogan_des_5: 'Fastest Deployment',
  },
  project: {
    btn_back: 'Back',
    feature_project: 'Feature Projects',
    feature_project_description: `We provide powerful software solutions, `,
    feature_project_description1: ` scalable and reliable for customers globally.`,
    feature_project_description_3: `<p className='ndaj-dt'>We provide powerful software solutions, </br>scalable and reliable for customers globally.</p>`,
    feature_project_description_4: `We provide powerful software solutions scalable and reliable for customers globally.`,
    search: 'Search',
    field: 'Lĩnh vực',
    language: 'Ngôn ngữ',
    tech: 'Công nghệ',
    back_home: 'QUAY VỀ TRANG CHỦ',
    load_more: 'Xem thêm',
    platform: 'Nền tảng',
    scale: 'Quy mô',
  },
  product: {
    product: 'SẢN PHẨM',
    product_des:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
  },
  news: {
    title_featured: 'TIN TỨC NỔI BẬT',
    featured_description:
      'Cùng điểm qua những tin tức đáng chú ý trong thời gian qua từ TCom nhé',
    title: 'Các tin tức mới nhất',
    news: 'TIN TỨC',
    new_des:
      'Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh',
    new_hot: 'TIN TỨC NỔI BẬT',
    new_hostlist:
      'Cùng điểm qua những tin tức đáng chú ý trong thời gian qua từ Eyefire nhé',
    list_hot: 'Các tin tức mới nhất',
    search: 'Search',
    relate: 'Tin liên quan',
    description:
      'EYEFIRE - Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
  },
  solution: {
    title_solution: 'Feature Solutions',
    title_solution_des:
      'TCOM Software has been developing many comprehensive technology platforms and solutions, promote the process of digital transformation of businesses and organizations.',
    content_solution:
      '<p>Giải pháp toàn diện, giải quyết các bài toán thực tế. Có thể tuỳ biến đáp ứng mọi nhu cầu</p>',
    our_service_sub1: 'Our Solutions',
    our_service_sub2: '',
  },

  text_button: {
    read_more: 'Read more',
    read_more_b: 'READ MORE',
    detail: 'Xem chi tiết',
    more: 'Tìm hiểu thêm',
    back: 'Trở lại',
  },

  contact: {
    send: 'CONTACT US',
    title: 'Contact Information',
    description: ` <p className='desc'>Just shoot over your details if you need some advice, a price quote, or to set up some sort of business partnership. You can contact us whenever you like by sending an email. We're here to hear you out and give you our full backing.</p>`,
    description_text: `Send information for advice, quotation, or business cooperation contact. Please email us at any time. We are ready to listen and support you!`,
    description_mb: ` <p className='desc'>Just shoot over your details if you need some advice, a price quote, or to set up some sort of business partnership. You can contact us whenever you like by sending an email. We're here to hear you out and give you our full backing.</p>`,
    tcom_vn: 'TCOM Software',
    contacttitle: 'Contact now',
    name: 'Họ tên',
    phone: 'Số điện thoại',
    tieu_de: 'Tiêu đề',
    purpose: 'Mục đích',
    content: 'Nội dung',
    submit: 'SUBMIT',
    member: 'HỆ THỐNG THÀNH VIÊN CỦA CHÚNG TÔI',
    subTitle:
      'TCOM Software không ngừng lớn mạnh, chúng tôi đem đến cho bạn những giải pháp, công nghệ tiện ích cuộc sống trong thời đại 4.0',
    option_a: 'Mục đích liên hệ',
    option_b: 'Báo giá',
    option_c: 'Yêu cầu tư vấn',
    option_d: 'Hợp tác',
    option_e: 'Tuyển dụng',
    option_f: 'Khác',
    title_l_a: 'Ha Noi Office',
    title_l_d_a:
      '3rd floor, Fafim A Building, 19 Nguyen Trai Street Thanh Xuan District, Ha Noi City, Viet Nam',

    title_l_b: 'Ho Chi Minh Office',
    title_l_d_b:
      '2F, H&H Building 102 Nguyen Dinh Chinh Street, Ward 15, Phu Nhuan District, Ho Chi Minh City, Viet Nam',

    title_l_c: 'Tokyo Office',
    title_l_d_c:
      '504 Tachikawa NX Building 5F, 3-8-5 Shibasakicho, Tachikawa, Tokyo, Japan 190-0023',
    location_a: 'Ha Noi Office',
    location_a_text:
      '3rd floor, Fafim A Building, 19 Nguyen Trai Street Thanh Xuan District, Ha Noi City, Viet Nam',

    location_b: 'Ho Chi Minh Office',
    location_b_text:
      '2F, H&H Building 102 Nguyen Dinh Chinh Street, Ward 15, Phu Nhuan District, Ho Chi Minh City, Viet Nam',

    location_c: 'Tokyo Office',
    location_c_text:
      '504 Tachikawa NX Building 5F, 3-8-5 Shibasakicho, Tachikawa, Tokyo, Japan 190-0023',
    introduction: 'INTRODUCE',
    href_a: 'Services',
    href_b: 'Solutions',
    href_c: 'Projects',

    services: 'SERVICES',
    form_mail: 'Work mail',
    form_name: 'Fullname',
    form_phone: 'Phone',
    form_subject: 'Subject',
    form_purpose: 'How can we help',
    form_quotation: 'Quotation',
    form_request: 'Request a consultation',
    form_cooperation: 'Cooperation',
    form_recruitment: 'Recruitment',
    form_orther: 'Other',
    form_message: 'Your message',
    form_file: 'File attachment',
  },

  error: {
    title: 'Not found',
    button: 'Back to home',
    name: 'Fullname is not blank !',
    mail: 'Email is not blank !',
    mail_format: 'Value must be email format',
    form_mail: 'Work mail',
    phone: 'Phone number is not blank !',
    phone_format: 'Value must be numeric format !',
    subject: 'Subject is not blank !',
    need: 'Please select a reason !',
    message: 'Message is not blank !',
    file: 'Please select a file attachment !',
  },
  seo: {
    home: {
      title: 'Trang chủ',
      description:
        'We are the leading Vietnam software outsourcing company focused on delivering the best and most cost-effective offshore service to clients all over the world',
    },
    technology: {
      title: 'Công nghệ',
      description:
        'Công nghệ nhận diện khuôn mặt, nhận diện phương tiện giao thông, nhận diện hành vi bất thường, phát hiện sản phẩm lỗi, phát hiện chuyển động, phát hiện xâm nhập, hàng rào ảo, AIoT camera',
    },
    solution: {
      title: 'Our Solutions',
      description:
        'TCOM Software has been developing many comprehensive technology platforms and solutions, promoting the digital transformation process of businesses and organizations',
    },
    project: {
      title: 'Projects',
      description: 'Our noteworthy projects to the delight of our clients',
    },
    news: {
      title: 'Tin tức',
      description:
        'Những tin tức, sự kiện nổi bật về hoạt động hợp tác kinh doanh, cũng như các hoạt động nội bộ của EYEFIRE',
    },
    sdk: {
      title: 'SDK',
      description:
        'Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng, các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK đa nền tảng bao gồm iOS SDK, Android SDK, Server SDK',
    },
    contact: {
      title: 'Contact Us',
      description:
        'Just shoot over your details if you need some advice, a price quote, or to set up some sort of business partnership. You can contact us whenever you like by sending an email. We are here to hear you out and give you our full backing',
    },
  },
  service: {
    our_service: 'Our Services',
    field: 'OUR STRENGTHS',
    field_description: 'Aspects in which we provide services',
    language_code: 'PROGRAMMING LANGUAGE',
    tech: 'TECHNOLOGY',
    field_second: 'FIELDS',
    our_service_sub1: 'Our Services',
    our_service_sub2: '',
    tool: 'TOOLS',
  },
  detail_service: {
    detail_service_a: 'Working process',
    detail_service_b: 'Simple, clear, and easy-to-collaborate working steps',
  },
  contact_a: 'CONTACT',
};

export default {
  home: {
    play_video: 'Xem video',
    office_title: 'Văn Phòng',
    office_video: 'Hình ảnh & Video',
    title: 'EYEFIRE',
    description_a: 'Phát triển sản phẩm dựa trên nền tảng Salesforce',
    description_b: 'Các giải pháp E-Commerce Website',
    description_c: 'Tư vấn và phát triển phần mềm',
    description_d: 'Dịch vụ offshore hàng đầu Việt Nam',
    sub_description_a:
      'Đội ngũ kỹ sư nhiều kinh nghiệm, có khả năng phát triển các sản phẩm, dịch vụ dựa trên nền tảng Salesforce, một nền tảng CRM tốt nhất hiện tại',
    sub_description_b:
      'Tư vấn, thiết kế và phát triển các hệ thống website thương mại điện tử đáp ứng mọi quy mô của doanh nghiệp',
    sub_description_c:
      'Tư vấn và phát triển giải pháp, sản phẩm phần mềm dựa trên công nghệ Trí tuệ nhân tạo A.I, Blockchain, AR/VR, Livestreaming cho các tổ chức, doanh nghiệp trong và quốc tế',
    sub_description_d:
      'Đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới. Quy trình phát triển phần mềm chuyên nghiệp, quy trình kiểm soát chất lượng nghiêm ngặt. Chúng tôi đã, đang và sẽ là đối tác của các khách hàng lớn trên toàn thế giới.',
    integrated_title1: 'On Premise',
    integrated_title2: 'Cloud Service',
    integrated_title3: 'Tích hợp',
    integrated_content1:
      'Triển khai trọn gói trên hạ tầng của đối tác, khách hàng',
    integrated_content2:
      'Sử dụng hạ tầng cloud server của EYEFIRE, triển khai nhanh chóng',
    integrated_content3:
      'MobileSDK, ServerSDK, APIs đáp ứng hầu hết các nhu cầu tích hợp',
    hotTech: 'Công nghệ nổi bật',
    solutions: 'Các giải pháp',
    solutions_des:
      'Giải pháp toàn diện, giải quyết các bài toán thực tế.</br> Có thể tuỳ biến đáp ứng mọi nhu cầu',
    product: 'SẢN PHẨM',
    product_sub:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
    facesdk:
      "<p>Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng,<br class='item-pc'/> các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK</p>",
    news: 'TIN TỨC',
    new_des:
      'Thông tin công nghệ, sản phẩm của EYEFIRE. Các sự kiện, hoạt động hợp tác, các thông báo và <br/> nhiều thông tin khác',

    field: 'CÁC LĨNH VỰC',
    field_sub: 'Công nghệ của EYEFIRE có thể ứng dụng trong mọi lĩnh vực',
    customer: 'Khách hàng và đối tác',
    customer_des:
      'EYEFIRE tự hào khi là đối tác của các doanh nghiệp, tổ chức hàng đầu',
    start: 'TÍCH HỢP VÀ TRIỂN KHAI',
    start_des:
      'Công nghệ nhận diện đa nền tảng, customize theo nhu cầu, linh động các hình thức triển khai',
    contact: 'Liên hệ để được tư vấn',
    slider_text:
      'TCOM Software với đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới. Quy trình phát triển phần mềm chuyên nghiệp, quy trình kiểm soát chất lượng nghiêm ngặt. Chúng tôi đã, đang và sẽ là đối tác của các khách hàng lớn trên toàn thế giới.',
    slider_text_icon_a: 'Chi phí tối ưu',
    slider_text_icon_b: 'Chất lượng tốt nhất',
    slider_text_icon_c: 'Triển khai nhanh nhất',
    our_service_title: 'CÁC DỊCH VỤ CHÚNG TÔI CUNG CẤP',
    sub_our_service_title1:
      'Chúng tôi cung cấp các giải pháp phần mềm mạnh mẽ, ',
    sub_our_service_title2:
      'có thể mở rộng và đáng tin cậy cho khách hàng trên toàn cầu.',
    sub_our_service_title:
      'Chúng tôi cung cấp các giải pháp phần mềm mạnh mẽ, có thể mở rộng và đáng tin cậy cho khách hàng trên toàn cầu.',
    our_service_item_title_a: 'Web Development',
    our_service_item_title_b: 'Mobile App Development',
    our_service_item_title_c: 'Video Solutions',
    our_service_item_title_d: 'Realtime Solutions',
    our_service_item_title_e: 'A.I Reseach & Development',
    our_service_item_title_f: 'Blockchain Development',
    our_service_item_title_g: 'QA & Testing',
    our_service_item_title_h: 'Maintenance & IT Managed',
    our_service_item_title_i: 'UI/UX Design',
    our_service_item_des_a:
      'Đội ngũ nhân sự với kinh nghiệm nhiều năm tư vấn, thiết kế, phát triển các sản phẩm trên nền tảng web.',
    our_service_item_des_b_1: 'Native Mobile App Development Hybrid',
    our_service_item_des_b_2: 'Mobile App Development Mobile',
    our_service_item_des_b_3: 'Application Re-Engineering',
    our_service_item_des_c:
      'Tư vấn giải pháp, phát triển các hệ thống xử lý, quản lý, phân phối video/live video với hiệu năng cao, chịu tải lớn, đáp ứng hàng triệu người dùng.',
    our_service_item_des_d:
      'Tư vấn, phát triển các hệ thống tương tác thời gian thực đa nền tảng với công nghệ mới và mạnh mẽ.',
    our_service_item_des_e:
      'Phát triển các giải pháp trí tuệ nhân tạo, dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, vật thể.',
    our_service_item_des_f:
      'Đội ngũ TCOM với nhiều chuyên gia trong lĩnh vực Blockchain, đáp ứng các nhu cầu phát triển sản phẩm, ứng dụng cho doanh nghiệp của bạn.',
    our_service_item_des_g:
      'Đội ngũ QA/QC của TCOM có nhiều năm kinh nghiệm trong kiểm thử phần mềm.',
    our_service_item_des_h:
      'Chúng tôi cung cấp dịch vụ offshore nhằm mang tới cho đối tác, khách hàng sản phẩm chất lượng với giá cả cạnh tranh nhất.',
    our_service_item_des_i:
      'Đội ngũ designer của TCOM có nhiều năm kinh nghiệm trong tư vấn, thiết kế UI/UX sản phẩm phần mềm.',
    our_service_methods: 'CÁC PHƯƠNG THỨC HỢP TÁC TCOM Software',
    sub_our_service_methods:
      'Chúng tôi cung cấp các giải pháp phần mềm mạnh mẽ, có thể mở rộng và đáng tin cậy cho khách hàng trên toàn cầu.',
    project_based_a:
      'Là hình thức offshore mà hợp đồng được ký kết theo từng dự án.',
    project_based_b:
      'Khách hàng xây dựng, quản lý, vận hành một team riêng theo yêu cầu, đặt tại TCOM Software.',
    procedure_title: 'Quy trình thực hiện',
    procedure_title_sub:
      'Các bước trao đổi, làm việc đơn giản, rõ ràng, dễ dàng hợp tác',
    procedure_step_1: 'Thu thập thông tin',
    procedure_step_2: 'Thiết kế',
    procedure_step_3: 'Lập trình',
    procedure_step_4: 'Testing',
    procedure_step_5: 'Triển khai',
    procedure_step_6: 'Đánh giá',
    procedure_step_sub_1:
      'Trao đổi idea, tư vấn, nghiên cứu và làm rõ các yêu cầu',
    procedure_step_sub_2:
      'Thiết kế kiến trúc hệ thống, phác thảo giao diện, thiết kế UI/UX',
    procedure_step_sub_3: 'Lập trình các chức năng',
    procedure_step_sub_4: 'Quản lý chất lượng, kiểm thử phần mềm, fix lỗi',
    procedure_step_sub_5: 'Triển khai chạy thử, chạy beta và chạy production',
    procedure_step_sub_6: 'Đánh giá chất lượng, hiệu năng',
    project_title: 'Dự án nổi bật',
    project_sub_title:
      'Một số dự án nổi bật mà chúng tôi đã triển khai thành công, làm khách hàng hài lòng',
    project_text:
      'Ứng dụng du lịch được cá nhân hóa, tất cả trong một, để book vé, khách sạn, nhà hàng, các tour du lịch trải nghiệm.',
    achievement_title: 'THÀNH TỰU NỔI BẬT',
    achievement_text:
      'Một số dự án nổi bật mà chúng tôi đã triển khai thành công, làm khách hàng hài lòng.',
    achievement_project: 'Dự án',
    achievement_customer: 'Khách hàng',
    achievement_engineer: 'Kỹ sư',
    achievement_expert: 'Chuyên gia',
    title: 'TCOM Software',
    desc: 'CUNG CẤP DỊCH VỤ OFFSHORE',
    feature_project: 'DỰ ÁN NỔI BẬT',
    feature_project_description:
      'Một số dự án nổi bật mà chúng tôi đã triển khai thành công, làm khách hàng hài lòng',
    language_tech: 'CÔNG NGHỆ VÀ NGÔN NGỮ',
    language_tech_sub:
      'Chúng tôi liên tục cập nhật và mở rộng năng lực công nghệ, nhằm đáp ứng tốt hơn nữa về chất lượng sản phẩm cho khách hàng.',
  },
  about: {
    our_title: 'VỀ CHÚNG TÔI',
    our_text:
      'TCOM Japan, thành lập tháng 05/2021, có trụ sở tại Tokyo. TCOM Japan chuyên cung cấp dịch vụ offshore, tư vấn hệ thống IT tại thị trường Nhật Bản. TCOM Japan có công ty mẹ là công ty TCOM tại Việt Nam - công ty công nghệ đa lĩnh vực, với đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới và quy trình phát triển phần mềm chuyên nghiệp, quy trình kiểm soát chất lượng nghiêm ngặt.',
    our_sub:
      'TCOM Japan đã, đang và sẽ là đối tác của các khách hàng lớn tại Nhật Bản',
    us_title: 'ĐỘI NGŨ CỦA CHÚNG TÔI',
    images: 'MỘT SỐ HÌNH ẢNH',
    profile_title: 'Company Profile',
    profile_text1:
      'Chúng tôi cung cấp dịch vụ offshore, tư vấn hệ thống IT và phát triển phần mềm. Các công nghệ nổi bật mà chúng tôi có thế mạnh như A.I, blockchain, AR/VR, video livestreaming.',
    download: 'Tải ngay Profile',
    achievement_title: 'THÀNH TÍCH',
    achievement_text:
      'Luôn mở rộng quy mô, nâng cao trình độ đội ngũ, quy trình kiểm soát chất lượng nghiêm ngặt, TCOM Japan đã và đang là đối tác tin cậy của các doanh nghiệp trong và ngoài nước.',
    achievement_project: 'Dự án',
    achievement_customer: 'Khách hàng',
    achievement_engineer: 'Kỹ sư',
    achievement_expert: 'Chuyên gia',
    title: 'TCOM Software',
    desc: 'CUNG CẤP DỊCH VỤ OFFSHORE',
    customer: 'KHÁCH HÀNG',
    customer_text: `Chúng tôi có những khách hàng từ khắp nới trên thế giới với sự tận tâm, trách nhiệm, sáng tạo chúng tôi đem đến đối tác những dịch vụ sản phẩm chất lượng và sự hài lòng cao nhất.`,
    customer_text1: ``,
  },
  layout: {
    vn: 'Viet Nam',
    el: 'English',
    jp: 'Japan',
    about: 'Về chúng tôi',
    service: 'Dịch vụ',
    home: 'Trang chủ',
    technology: 'Công nghệ',
    project: 'Dự án',
    product: 'Sản phẩm',
    solution: 'Giải pháp',
    recruitment: 'Tuyển dụng',
    sdk: 'SDK',
    job: 'Tuyển dụng',
    news: 'Tin tức',
    contact: 'Liên hệ',
    slogan:
      'Tiên phong trong các sản phẩm và giải <br class="item-sp"> pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh.',
    office: 'OFFICE',
    menu: 'Menu',
    adrHn: 'TRỤ SỞ',
    adrHcm: 'CHI NHÁNH HỒ CHÍ MINH',
    adrJp: 'CHI NHÁNH NHẬT BẢN',
    address1:
      'Tầng 3 tòa nhà Fafim A, Số 19 Nguyễn Trãi,</br> Phường Khương Trung, Quận Thanh Xuân, TP Hà Nội',
    address2:
      'Tầng 2, Toà nhà văn phòng, Số 102 Nguyễn Đình Chính,</br> Phường 15, Quận Phú Nhuận, TP Hồ Chí Minh',
    address3: '〒190-0023 <br />東京都立川市柴崎町3-8-5立川NXビル5F',
    rule1: 'Điều khoản dịch vụ',
    rule2: 'Chính sách bảo mật',
    keep: 'KEEP IN TOUCH',
    copyright: 'Copyright © 2021EYEFIRE Corporation. All rights reserved.',
    contact_footer: 'LIÊN HỆ',
    content_contact_footer1:
      'Hãy gửi cho chúng tôi những thắc mắc, góp ý hoặc đề nghị hợp tác của bạn.',
    content_contact_footer2: 'Chúng tôi sẽ phản hồi trong thời gian sớm nhất!',
    button_contact: 'GỬI LIÊN HỆ HỢP TÁC',
    button_detail: 'Xem chi tiết',
    btn_send: 'Gửi thông tin',
    title_contact: 'Để lại thông tin liên hệ',
    content_contact1:
      'Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh.<br /> Vui lòng gửi email cho chúng tôi bất cứ lúc nào.',
    content_contact2: 'Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
    footer_title: 'TCOM',
  },
  off_shore: {
    text: 'Offshore',
    offshore_d:
      'TCOM Software trung tâm offshore, đối tác tin cậy của các khách hàng Nhật. Chúng tôi cung cấp dịch vụ offshore nhằm mang tới cho đối tác, khách hàng sản phẩm chất lượng với giá cả cạnh tranh nhất.',
    title: 'OFFSHORE SERVICE',
    title_dis: 'TCOM Software',
    introduce:
      'Offshore có thể hiểu đơn giản là việc ủy thác một phần công việc của mình cho các công ty ở nước ngoài - những khu vực có giá cả và chi phí nhân công thấp, với mục đích giảm chi phí.',
    benefit: 'OFFSHORE MANG LẠI CHO KHÁCH HÀNG ĐIỀU GÌ ?',
    benefit_description:
      'Nguồn lực nội bộ không thể giải quyết vấn đề đặt ra: nhân sự ít, kỹ thuật khó, mất nhiều thời gian để thực hiện, xử lý. Muốn giảm chi phí: Offshore có thể giảm được chi phí rất nhiều so với khi tự làm hoặc thuê người ở nước sở tại. Tập trung cho công việc trọng yếu: Offshore phần công việc không phải là trọng yếu, dành nguồn lực tốt nhất cho những công việc tạo ra nhiều giá trị. Công ty startup muốn có sản phẩm nhanh: rất nhiều công ty startup đang Offshore để có sản phẩm nhanh chóng với mức chi phí rẻ.',
    content_title:
      'Chúng tôi cung cấp dịch vụ Offshore chất lượng tốt nhất, chi phí tối ưu nhất',
    awards: 'THÀNH TÍCH ĐẠT ĐƯỢC',
    why_choose: 'Lý do bạn nên chọn TCOM Software',
    reason_a: 'Chi phí hợp lý, tiết kiệm cho bạn 60%',
    reason_b: 'Có quản lý chất lượng người Nhật ',
    reason_c: 'Đội ngũ kỹ sư trình độ cao, quy trình chuyên nghiệp',
    method: 'CÁC PHƯƠNG THỨC HỢP TÁC',
    method_1: 'Project based',
    method_des_1:
      'Là hình thức offshore mà hợp đồng được ký kết theo từng dự án. TCOM Software sẽ quản lý team và mọi hoạt động trong quy trình phát triển dự án bao gồm các tài liệu mô tả từ khách hàng, kiến trúc kỹ thuật, thiết kế, lập trình, kiểm thử, triển khai sản phẩm.',
    method_2: 'Dedicated team (labor)',
    method_des_2:
      'Khách hàng xây dựng, quản lý, vận hành một team riêng theo yêu cầu, đặt tại TCOM Software. Labor đảm bảo nhân lực ổn định cho dự án.',
    pattern_1: 'Pattern 1',
    pattern_des_1:
      'Khách hàng và BrSE của công ty làm việc và trao đổi trực tiếp',
    pattern_2: 'Pattern 2',
    pattern_des_2:
      'BrSE sang làm việc bên Khách hàng, và lập team phụ trách bên Việt Nam',
    service: 'CÁC DỊCH VỤ CHÚNG TÔI CUNG CẤP',
    procedure: 'QUY TRÌNH THỰC HIỆN',
    procedure_des:
      'Các bước trao đổi, làm việc đơn giản, rõ ràng, dễ dàng hợp tác',
    procedure_1: 'Thu thập thông tin',
    procedure_des_1: '',
    slogan1:
      ' Chúng tôi cung cấp dịch vụ Offshore chất lượng tốt nhất, chi phí tối ưu nhất ',
    slogan_des_1: 'Chi phí tối ưu',
    slogan_des_3: 'Chất lượng tốt nhất',
    slogan_des_5: 'Triển khai nhanh nhất',
  },
  project: {
    btn_back: 'Back',
    feature_project: 'Các dự án nổi bật',
    feature_project_description: `Chúng tôi cung cấp các giải pháp phần mềm mạnh mẽ, `,
    feature_project_description1: ` có thể mở rộng và đáng tin cậy cho khách hàng trên toàn cầu.`,
    feature_project_description_3: `<p className='ndaj-dt'>Chúng tôi cung cấp các giải pháp phần mềm mạnh mẽ, </br>có thể mở rộng và đáng tin cậy cho khách hàng trên toàn cầu.</p>`,
    feature_project_description_4: `Chúng tôi cung cấp các giải pháp phần mềm mạnh mẽ có thể mở rộng và đáng tin cậy cho khách hàng trên toàn cầu.`,
    search: 'Tìm kiếm',
    field: 'Lĩnh vực',
    language: 'Ngôn ngữ',
    tech: 'Công nghệ',
    back_home: 'QUAY VỀ TRANG CHỦ',
    load_more: 'Xem thêm',
    platform: 'Nền tảng',
    scale: 'Quy mô',
  },
  product: {
    product: 'SẢN PHẨM',
    product_des:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
  },
  news: {
    title_featured: 'TIN TỨC NỔI BẬT',
    featured_description:
      'Cùng điểm qua những tin tức đáng chú ý trong thời gian qua từ TCOM Software nhé',
    title: 'Các tin tức mới nhất',
    news: 'TIN TỨC',
    new_des:
      'Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh',
    new_hot: 'TIN TỨC NỔI BẬT',
    new_hostlist:
      'Cùng điểm qua những tin tức đáng chú ý trong thời gian qua từ Eyefire nhé',
    list_hot: 'Các tin tức mới nhất',
    search: 'Tìm kiếm',
    relate: 'Tin liên quan',
    description:
      'EYEFIRE - Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
  },
  solution: {
    title_solution: 'CÁC GIẢI PHÁP NỔI BẬT',
    title_solution_des:
      'TCOM Software đã và đang phát triển nhiều nền tảng, giải pháp công nghệ toàn diện, thúc đẩy quá trình chuyển đổi số của doanh nghiệp, tổ chức.',
    content_solution:
      '<p>Giải pháp toàn diện, giải quyết các bài toán thực tế. Có thể tuỳ biến đáp ứng mọi nhu cầu</p>',
    our_service_sub1: 'Các giải pháp',
    our_service_sub2: 'của chúng tôi',
  },

  text_button: {
    read_more: 'Xem thêm',
    read_more_b: 'XEM THÊM',
    detail: 'Xem chi tiết',
    more: 'Tìm hiểu thêm',
    back: 'Trở lại',
  },

  contact: {
    send: 'GỬI LIÊN HỆ HỢP TÁC',
    title: 'Để lại thông tin liên hệ',
    description: ` <p className='desc'>Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh. Vui lòng gửi email cho chúng tôi bất cứ lúc nào. Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.</p>`,
    description_text: `Hãy gửi cho chúng tôi những thắc mắc, góp ý hoặc đề nghị hợp tác của bạn. Chúng tôi sẽ phản hồi trong thời gian sớm nhất!`,
    description_mb: ` <p className='desc'>Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh. Vui lòng gửi email cho chúng tôi bất cứ lúc nào. Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.</p>`,
    tcom_vn: 'TCOM Software',
    contacttitle: 'Liên hệ ngay',
    name: 'Họ tên',
    phone: 'Số điện thoại',
    tieu_de: 'Tiêu đề',
    purpose: 'Mục đích',
    content: 'Nội dung',
    submit: 'Gửi thông tin',
    member: 'HỆ THỐNG THÀNH VIÊN CỦA CHÚNG TÔI',
    subTitle:
      'TCOM Software không ngừng lớn mạnh, chúng tôi đem đến cho bạn những giải pháp, công nghệ tiện ích cuộc sống trong thời đại 4.0',
    option_a: 'Mục đích liên hệ',
    option_b: 'Báo giá',
    option_c: 'Yêu cầu tư vấn',
    option_d: 'Hợp tác',
    option_e: 'Tuyển dụng',
    option_f: 'Khác',
    title_l_a: 'TRỤ SỞ HÀ NỘI',
    title_l_d_a:
      'Tầng 3 tòa nhà Fafim A, Số 19 Nguyễn Trãi, Phường Khương Trung, Quận Thanh Xuân, TP Hà Nội',

    title_l_b: 'CHI NHÁNH HỒ CHÍ MINH',
    title_l_d_b:
      'Tầng 2, Toà nhà văn phòng, Số 102 Nguyễn Đình Chính, Phường 15, Quận Phú Nhuận, TP Hồ Chí Minh',

    title_l_c: 'CHI NHÁNH NHẬT BẢN',
    title_l_d_c: '〒190-0023 東京都立川市柴崎町3-8-5立川NXビル5F',
    location_a: 'Trụ sở Hà Nội',
    location_a_text:
      'Tầng 3 tòa nhà văn phòng Fafim A, Số 19 Nguyễn Trãi, Phường Khương Trung, Quận Thanh Xuân, Hà Nội',

    location_b: 'Văn phòng Hồ Chí Minh',
    location_b_text:
      ' Tầng 2, Toà nhà số 102 Nguyễn Đình Chính, Phường 15, Quận Phú Nhuận, TP Hồ Chí Minh',

    location_c: 'Văn phòng Tokyo',
    location_c_text: '〒190-0023 東京都立川市柴崎町3-8-5',
    introduction: 'GIỚI THIỆU',
    href_a: 'Dịch vụ',
    href_b: 'Giải pháp',
    href_c: 'Dự án',

    services: 'CÁC DỊCH VỤ',

    form_name: 'Họ tên',
    form_mail: 'Email',
    form_phone: 'Số điện thoại',
    form_subject: 'Tiêu đề',
    form_purpose: 'Mục đích liên hệ',
    form_quotation: 'Báo giá',
    form_request: 'Yêu cầu tư vấn',
    form_cooperation: 'Hợp tác',
    form_recruitment: 'Tuyển dụng',
    form_orther: 'Khác',
    form_message: 'Nội dung liên hệ',
    form_file: 'Đính kèm file',
  },

  error: {
    title: 'Không tìm thấy trang',
    button: 'Quay về trang chủ',

    name: 'Vui lòng nhập tên!',
    mail: 'Vui lòng nhập email!',
    mail_format: 'Vui lòng nhập email đúng định dạng!',

    phone: 'Vui lòng nhập số điện thoại!',
    phone_format: 'Vui lòng nhập định dạng số!',
    subject: 'Vui lòng nhập tiêu đề!',
    need: 'Vui lòng chọn mục đích!',
    message: 'Vui lòng nhập nội dung liên hệ!',
    file: 'Vui lòng chọn file đính kèm!',
  },
  seo: {
    home: {
      title: 'Trang chủ',
      description:
        'Chúng tôi là công ty phát triển phần mềm hàng đầu Việt Nam, chuyên cung cấp dịch vụ Offshore với chất lượng tốt nhất, chi phí tiết kiệm nhất cho khách hàng trên toàn thế giới',
    },
    technology: {
      title: 'Công nghệ',
      description:
        'Công nghệ nhận diện khuôn mặt, nhận diện phương tiện giao thông, nhận diện hành vi bất thường, phát hiện sản phẩm lỗi, phát hiện chuyển động, phát hiện xâm nhập, hàng rào ảo, AIoT camera',
    },
    solution: {
      title: 'Giải pháp',
      description:
        'TCOM Software đã và đang phát triển nhiều nền tảng, giải pháp công nghệ toàn diện, thúc đẩy quá trình chuyển đổi số của doanh nghiệp, tổ chức.',
    },
    project: {
      title: 'Dự án',
      description:
        'TCOM Software đã triển khai nhiều dự án lớn, ở hầu hết các lĩnh vực, làm khách hàng trong và ngoài nước hài lòng.',
    },
    news: {
      title: 'Tin tức',
      description:
        'Những tin tức, sự kiện nổi bật về hoạt động hợp tác kinh doanh, cũng như các hoạt động nội bộ của EYEFIRE',
    },
    sdk: {
      title: 'SDK',
      description:
        'Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng, các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK đa nền tảng bao gồm iOS SDK, Android SDK, Server SDK',
    },
    contact: {
      title: 'Liên hệ',
      description:
        'Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh. Vui lòng gửi email cho chúng tôi bất cứ lúc nào. Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
    },
  },
  service: {
    our_service: 'Các dịch vụ của chúng tôi',
    field: 'THẾ MẠNH CỦA CHÚNG TÔI',
    field_description: 'Các lĩnh vực mà chúng tôi cung cấp dịch vụ',
    language_code: 'NGÔN NGỮ LẬP TRÌNH',
    tech: 'CÔNG NGHỆ',
    field_second: 'LĨNH VỰC',
    our_service_sub1: 'Các dịch vụ',
    our_service_sub2: 'của chúng tôi',
    tool: 'CÔNG CỤ',
  },
  detail_service: {
    detail_service_a: 'Quy trình thực hiện',
    detail_service_b:
      'Các bước trao đổi, làm việc đơn giản, rõ ràng, dễ dàng hợp tác',
  },
  contact_a: 'LIÊN HỆ',
};

import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import useTrans from '../hooks/useTrans';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { route } from 'next/dist/server/router';

function Header({ show_header }) {
  const ref = useRef();
  const trans = useTrans();
  const [show, setShow] = useState(false);
  const [none, setNone] = useState(false);
  const [value_select, setValueSelect] = useState('vi');
  const [detail, setDetail] = useState(-1);
  const router = useRouter();

  useEffect(() => {
    localStorage.setItem('lg', router.locale);
    setValueSelect(router.locale);
    setDetail(router.asPath.search(`/news/`));
  }, [router.locale, router.asPath]);
  useLayoutEffect(() => {
    setDetail(router.asPath.search(`/news/`));
  }, [router.pathname]);
  const setLanguageSelect = key => {
    localStorage.setItem('lg', key);
    setValueSelect(key);
    setShow('');
    setNone('');
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: key });
  };
  useEffect(() => {
    (() => {
      if (show) {
        lockScroll();
      } else {
        unlockScroll();
      }
    })();
  }, [show]);
  useEffect(() => {
    (() => {
      if (show) {
        lockScroll();
      } else {
        unlockScroll();
      }
    })();
  }, [show]);
  const lang = () => {
    setNone(!none);
  };

  const displayDrop = () => {
    setShow(!show);
  };
  let positionY = React.useRef(null);
  const lockScroll = React.useCallback(() => {
    positionY.current = window.scrollY;
    document.body.style.overflowY = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `${positionY.current}px`;
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflowY = '';
    document.body.style.position = '';
    document.body.style.top = '';
    scrollTo(0, positionY.current);
  }, []);

  const checkLogo = show_header.split(' ').filter(data => data === 'bgrColor');

  return (
    <div
      className="l-header"
      // style={{ background: `${detail === 0 ? '#000E33' : ''}` }}
    >
      {/* <!-- header desktop --> */}
      <div className="l-header-desktop">
        <div className="l-container-fluid">
          <div className="l-row">
            <div
              className="l-col-auto"
              style={{
                width: '25%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'start',
              }}
            >
              <Link href="/" className="l-header-desktop__logo">
                <div style={{ maxWidth: '113px' }}>
                  <img
                    style={{ cursor: 'pointer', width: '100%' }}
                    src={
                      checkLogo.length > 0
                        ? '/assets/img/slider/fig_logo_white_a.svg'
                        : router.pathname === '/'
                        ? '/assets/img/common/fig_logo_a.svg'
                        : '/assets/img/slider/fig_logo_white_a.svg'
                    }
                    alt=""
                  />
                </div>
              </Link>
            </div>
            <div
              className="l-col l-header-desktop__right"
              style={{ width: '70%' }}
            >
              <div className="l-header-desktop__right__inner">
                <div className="l-header-desktop__nav">
                  <div className="l-header-desktop__menu">
                    <ul>
                      <li className={router.pathname === '/' ? 'active' : ''}>
                        <Link href="/">
                          <a>{trans.layout.home}</a>
                        </Link>
                      </li>

                      <li
                        className={
                          router.pathname === '/service' ||
                          router.pathname.includes('/service')
                            ? 'active'
                            : ''
                        }
                      >
                        <Link href="/service">
                          <a>{trans.layout.service}</a>
                        </Link>
                      </li>
                      <li
                        className={
                          router.pathname === '/solution' ? 'active' : ''
                        }
                      >
                        <Link href="/solution">
                          <a>{trans.layout.solution}</a>
                        </Link>
                      </li>
                      <li
                        className={
                          router.pathname === '/project' ||
                          router.pathname.includes('/project')
                            ? 'active'
                            : ''
                        }
                      >
                        <Link href="/project">
                          <a>{trans.layout.project}</a>
                        </Link>
                      </li>

                      <li
                        className={
                          router.pathname === '/contact' ? 'active' : ''
                        }
                      >
                        <Link href="/contact">
                          <a>{trans.layout.contact}</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="l-header-desktop__group-right">
                    <div className="l-header-desktop__languages">
                      <a
                        style={
                          checkLogo.length > 0
                            ? { color: '#000' }
                            : router.pathname === '/'
                            ? { color: '#fff' }
                            : { color: '#000' }
                        }
                      >
                        <img
                          src={
                            router.locale === 'jp'
                              ? '/assets/img/common/jp.png'
                              : router.locale === 'en'
                              ? '/assets/img/common/en.png'
                              : '/assets/img/common/vn.png'
                          }
                        />
                        <span>
                          {router.locale === 'jp'
                            ? 'Japan'
                            : router.locale === 'en'
                            ? 'English'
                            : 'Viet Nam'}
                        </span>
                        <img src="/assets/img/common/icn_down.svg" alt="" />
                      </a>
                      <div className="l-header-desktop__languages__dropdown">
                        <ul>
                          <li>
                            <a onClick={() => setLanguageSelect('jp')}>
                              <img
                                className="mulFlags"
                                src="/assets/img/common/jp.png"
                              />
                              <span>{trans.layout.jp}</span>
                            </a>
                          </li>
                          <li>
                            <a onClick={() => setLanguageSelect('en')}>
                              <img
                                className="mulFlags"
                                src="/assets/img/common/en.png"
                              />
                              <span>{trans.layout.el}</span>
                            </a>
                          </li>
                          <li>
                            <a onClick={() => setLanguageSelect('vi')}>
                              <img
                                className="mulFlags"
                                src="/assets/img/common/vn.png"
                              />
                              <span>{trans.layout.vn}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end header desktop --> */}

      {/* <!-- header mobile --> */}
      <div class={show ? 'l-header-mobile show-canvas ' : 'l-header-mobile'}>
        <div className="l-container">
          <div className="l-row">
            <div className="l-col-auto l-header-mobile__left">
              <Link href="/" className="l-header-mobile__logo">
                {/* <img src={router.pathname === "/" || !router.pathname.includes("/project") ? "/assets/img/common/fig_logo_a.svg" : "/assets/img/slider/fig_logo_white_a.png"} alt="" /> */}
                <img
                  style={{ cursor: 'pointer' }}
                  src={
                    checkLogo.length > 0
                      ? '/assets/img/slider/fig_logo_white_a.svg'
                      : router.pathname === '/'
                      ? '/assets/img/common/fig_logo_a.svg'
                      : '/assets/img/slider/fig_logo_white_a.svg'
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="l-col l-header-mobile__right">
              <a
                className="l-header-mobile__menu js-open-menu"
                onClick={displayDrop}
              >
                <img
                  style={
                    checkLogo.length > 0
                      ? { filter: 'invert(0)' }
                      : router.pathname === '/'
                      ? { filter: 'invert(1)' }
                      : { filter: 'invert(0)' }
                  }
                  src="/assets/img/common/icn_menu-bar.svg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="l-header-mobile__canvas ">
          <div className="l-container">
            <div className="l-row">
              <div className="l-col-auto l-header-mobile__left">
                <Link href="/" className="l-header-mobile__logo">
                  <img src="/assets/img/slider/fig_logo_white_a.svg" alt="" />
                </Link>
              </div>
              <div className="l-col l-header-mobile__right">
                <a
                  className="l-header-mobile__menu js-close-menu"
                  onClick={displayDrop}
                >
                  <img src="/assets/img/common/icn_menu-bar.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="l-header-mobile__canvas__inner">
              <div className="l-header-mobile__canvas__menu">
                <ul>
                  <li
                    className={router.pathname === '/' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="/">
                      <a>{trans.layout.home}</a>
                    </Link>
                  </li>
                  {/* <li
                    className={router.pathname === '/about' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="/about">
                      <a>{trans.layout.about}</a>
                    </Link>
                  </li> */}
                  <li
                    className={router.pathname === '/service' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="/service">
                      <a>{trans.layout.service}</a>
                    </Link>
                  </li>
                  <li
                    className={router.pathname === '/solution' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="/solution">
                      <a>{trans.layout.solution}</a>
                    </Link>
                  </li>
                  <li
                    className={router.pathname === '/project' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="/project">
                      <a>{trans.layout.project}</a>
                    </Link>
                  </li>
                  {/* <li
                    className={router.pathname === '/tuyendung' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="https://tuyendung.tcom.vn/">
                      <a target="_blank"> {trans.layout.recruitment}</a>
                    </Link>
                  </li> */}
                  {/* <li
                    className={router.pathname === '/news' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="/news">
                      <a>{trans.layout.news}</a>
                    </Link>
                  </li> */}
                  <li
                    className={router.pathname === '/contact' ? 'active' : ''}
                    onClick={displayDrop}
                  >
                    <Link href="/contact">
                      <a>{trans.layout.contact}</a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="l-header-mobile__canvas__languages">
                <a className="js-open-dropdown-languages" onClick={lang}>
                  <img
                    src={
                      router.locale === 'jp'
                        ? '/assets/img/common/jp.png'
                        : router.locale === 'en'
                        ? '/assets/img/common/en.png'
                        : '/assets/img/common/vn.png'
                    }
                  />
                  <span>
                    {router.locale === 'jp'
                      ? 'JP'
                      : router.locale === 'en'
                      ? 'EN'
                      : 'VN'}
                  </span>
                  <img src="/assets/img/common/icn_down.svg" alt="" />
                </a>
                <div
                  className={
                    none
                      ? 'l-header-mobile__canvas__languages__dropdown showLang jqyfshfywyyssd'
                      : 'l-header-mobile__canvas__languages__dropdown jqyfshfywyyssd'
                  }
                >
                  <ul>
                    
                    <li>
                      <a
                        onClick={() => {
                          setLanguageSelect('jp');
                          setShow(!show);
                        }}
                      >
                        <img
                          className="mulFlags"
                          src="/assets/img/common/jp.png"
                        />
                        <span>{trans.layout.jp}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setLanguageSelect('en');
                          setShow(!show);
                        }}
                      >
                        <img
                          className="mulFlags"
                          src="/assets/img/common/en.png"
                        />
                        <span>{trans.layout.el}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={e => {
                          setLanguageSelect('vi');
                          setShow(!show);
                        }}
                      >
                        <img
                          className="mulFlags"
                          src="/assets/img/common/vn.png"
                        />
                        <span>{trans.layout.vn}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end header mobile --> */}
    </div>
  );
}
export default React.memo(Header);

import PropTypes from 'prop-types';
import Head from 'next/head';
import Link from 'next/link';
import { Form, message, Select } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import MetaSeo from './MetaSeo';
import axios from 'axios';
import { isIOS } from 'react-device-detect';
import { getAllServices } from '../redux/actions/services';
import { getDisplayName } from 'next/dist/shared/lib/utils';
import useTrans from '../hooks/useTrans';
import Header from './Header';
function Layout({ children, webViewMobile, web }) {
  const [infor, setInfor] = useState();
  const trans = useTrans();
  const dispatch = useDispatch();
  const [service, setService] = useState([]);
  const [show, setShow] = useState(false);
  const [none, setNone] = useState(false);
  const [marginTop, setMarginTop] = useState(0);
  const [show_menu_mobile, setShowMenuMobile] = useState();

  const [show_header, setShowHeader] = useState('sticky');
  const [padding_top, setPaddingTop] = useState('');
  const router = useRouter();
  const [y, setY] = useState(0);
  let positionY = React.useRef(null);
  const refClickLanguage = useRef();
  useEffect(() => {
    localStorage.setItem('lg', router.locale);
    getListServiceAll();
    getInfor(router.locale);
    window.addEventListener('scroll', onScroll);
  }, [router.locale]);

  const getInfor = async locale => {
    try {
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_API_SERVER_URL}/app/home/get-list-home-information`,
        {
          headers: {
            'Content-Type': 'application/json',
            locale: locale,
          },
        },
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getListServiceAll = async () => {
    const data = await getAllServices({}, dispatch);
    if (data) {
      setService(data?.rows);
    }
  };

  useEffect(() => {
    let head = document.getElementsByTagName('head')[0];
    let link = document.createElement('link');
    link.rel = 'stylesheet';

    if (router.locale === 'en' || router.locale === 'jp') {
      let lisLink = head.querySelector(
        `#${router.locale === 'en' ? 'jp' : 'en'}`,
      );
      if (lisLink) {
        head.removeChild(lisLink);
      }
    }
    if (router.locale === 'vi') {
      let lisLink = head.querySelectorAll(`.newLanguage`);

      if (lisLink.length) {
        head.removeChild(lisLink[0]);
      }
    }
    if (router.locale === 'en') {
      link.id = 'en';
      link.className = 'newLanguage';
      link.href = '/assets/css/styleEN.css';
    }

    if (router.locale === 'jp') {
      link.id = 'jp';
      link.className = 'newLanguage';
      link.href = '/assets/css/styleJP.css';
    }
    head.appendChild(link);

    return () => {
      // console.log(head);
    };
  }, [router?.locale]);

  const onScroll = e => {
    // let window = e.currentTarget;
    if (!window.vinhY) {
      window.vinhY = 0;
    }
    if (window.pageYOffset > 10) {
      if (window.vinhY > window.pageYOffset) {
        setShowHeader('sticky bgrColor');
      } else if (window.vinhY < window.pageYOffset) {
        setShowHeader('hiddenn');
      }
    } else {
      setShowHeader('sticky');
    }

    window.vinhY = window.pageYOffset;
  };

  let arr = router.components || [];
  let result = arr
    ? Object.keys(router?.components || []).includes('/404')
    : false;

  return (
    <>
      {/* <MetaSeo {...dataSeo} /> */}
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>TCOM Software</title>
        <link rel="shortcut icon" href="/assets/img/slider/favicon.svg"></link>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <div id={web}>
        <div id={`header`} className={`${show_header}`}>
          <Header show_header={show_header} />
        </div>
        <main>{children}</main>

        {/* {router.pathname.includes('/contact') ? null : ( */}
        <div className="r-contact">
          <div className="container container-r-contact">
            <div className="contact-box">
              <div className="title-left">
                <h3>{trans.layout.contact}</h3>
                {/* <p className='desc'>{trans.layout.contact_description}</p> */}
                <p className="desc">{trans.contact.description_text}</p>
              </div>
              <Link href="/contact">
                <a
                  className="btn-contact"
                  style={
                    router.locale !== 'vi'
                      ? { textDecoration: 'none', justifyContent: 'center' }
                      : { textDecoration: 'none' }
                  }
                >
                  {/* <svg
                    width={25}
                    height={25}
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.9926 4.13984C21.9017 4.13047 21.8101 4.13047 21.7192 4.13984H3.05255C2.93291 4.14168 2.81407 4.15962 2.69922 4.19317L12.3326 13.7865L21.9926 4.13984Z"
                      fill="white"
                    />
                    <path
                      d="M22.9799 5.06738L13.2732 14.734C13.0234 14.9824 12.6855 15.1218 12.3332 15.1218C11.981 15.1218 11.643 14.9824 11.3932 14.734L1.77323 5.14072C1.74366 5.24941 1.72797 5.36141 1.72656 5.47405V18.8074C1.72656 19.161 1.86704 19.5001 2.11709 19.7502C2.36714 20.0002 2.70627 20.1407 3.0599 20.1407H21.7266C22.0802 20.1407 22.4193 20.0002 22.6694 19.7502C22.9194 19.5001 23.0599 19.161 23.0599 18.8074V5.47405C23.0546 5.33515 23.0276 5.19794 22.9799 5.06738ZM3.97323 18.8074H3.04656V17.854L7.89323 13.0474L8.83323 13.9874L3.97323 18.8074ZM21.7132 18.8074H20.7799L15.9199 13.9874L16.8599 13.0474L21.7066 17.854L21.7132 18.8074Z"
                      fill="white"
                    />
                  </svg> */}
                  <img
                    src="/assets/img/slider/mail-ico.svg"
                    alt=""
                    className="lakhjwqhfhahashda"
                  />
                  {trans.contact.send}
                </a>
              </Link>
            </div>
          </div>
        </div>
        {/* )} */}
        {/* footer */}
        <div id="footer">
          {/* footer main */}
          <div className="container">
            <div className="main">
              <div className="row">
                <div className="col-12 col-xl-3">
                  <a href="#" className="logo">
                    <img
                      src="/assets/images/logo-footer.svg"
                      alt="TCOM Software"
                    />
                  </a>
                  <p
                    className="textLogo"
                    style={{
                      fontWeight: '700',
                      fontSize: '24px',
                      lineHeight: '28px',
                    }}
                  >
                    {trans.layout.keep}
                  </p>
                  <div className="iconLogo">
                    <ul style={{ padding: '0', margin: '0' }}>
                      <li>
                        <a
                          href="https://www.facebook.com/TCOM.Corporation"
                          target={'_blank'}
                        >
                          <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16.5" cy="16.5" r="16.5" fill="white" />
                            <path
                              d="M20.1116 10.2951H21.9233V7.13974C21.6107 7.09674 20.5358 7 19.2839 7C16.6718 7 14.8825 8.643 14.8825 11.6627V14.4419H12V17.9693H14.8825L14.8825 26.845H18.4165V17.9701H21.1824L21.6215 14.4427H18.4157V12.0125C18.4165 10.993 18.6911 10.2951 20.1116 10.2951Z"
                              fill="#0063AA"
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/tcom-corporation"
                          target={'_blank'}
                        >
                          <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.4999 0C25.6131 0 33 7.38787 33 16.5001C33 25.6122 25.6131 33 16.4999 33C7.38674 33 0 25.6121 0 16.5001C0 7.38798 7.38685 0 16.4999 0Z"
                              fill="white"
                            />
                            <path
                              d="M9.29779 22.671H12.3874V12.3729H9.29779V22.671ZM21.3346 12.0162C19.8351 12.0162 18.4935 12.5637 17.5417 13.7724V12.339H14.4407V22.6711H17.5417V17.0837C17.5417 15.9029 18.6235 14.751 19.9785 14.751C21.3336 14.751 21.6677 15.9029 21.6677 17.0549V22.67H24.7574V16.8249C24.7573 12.7648 22.8351 12.0162 21.3346 12.0162ZM10.8282 11.3437C11.6821 11.3437 12.3751 10.6507 12.3751 9.79681C12.3751 8.94292 11.6821 8.25 10.8282 8.25C9.97428 8.25 9.28125 8.94303 9.28125 9.79692C9.28125 10.6508 9.97428 11.3437 10.8282 11.3437Z"
                              fill="#0063AA"
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCmeadb8H2pD_05cFz0qvDVw"
                          target={'_blank'}
                        >
                          <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16.5" cy="16.5" r="16.5" fill="white" />
                            <path
                              d="M24.6452 12.8745C24.5484 12.5115 24.3587 12.1805 24.095 11.9146C23.8313 11.6487 23.5028 11.4572 23.1425 11.3592C21.8163 11 16.5 11 16.5 11C16.5 11 11.1837 11 9.85748 11.3573C9.49703 11.4549 9.16842 11.6463 8.90465 11.9123C8.64089 12.1783 8.45126 12.5095 8.3548 12.8726C8 14.2102 8 17 8 17C8 17 8 19.7898 8.3548 21.1255C8.55022 21.8631 9.12701 22.4439 9.85748 22.6408C11.1837 23 16.5 23 16.5 23C16.5 23 21.8163 23 23.1425 22.6408C23.8749 22.4439 24.4498 21.8631 24.6452 21.1255C25 19.7898 25 17 25 17C25 17 25 14.2102 24.6452 12.8745ZM14.8114 19.5605V14.4395L19.2132 16.9809L14.8114 19.5605Z"
                              fill="#0063AA"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12 col-xl-3 col-service">
                  <div
                    className="customMarginTop"
                    style={{ marginTop: '155px' }}
                  >
                    <ul
                      className="customPaddingUl"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <li>
                        <div className="icon">
                          {/* Dũng thêm display */}
                          <div
                            style={{ color: 'white', display: 'inline-flex' }}
                            className="customMarginBottom"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  d="M4.5 3V19.5H13.5V3H4.5ZM3.75 1.5H14.25C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V20.25C15 20.4489 14.921 20.6397 14.7803 20.7803C14.6397 20.921 14.4489 21 14.25 21H3.75C3.55109 21 3.36032 20.921 3.21967 20.7803C3.07902 20.6397 3 20.4489 3 20.25V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5Z"
                                  fill="white"
                                />
                                <path
                                  d="M6 6H12V7.5H6V6ZM6 10.5H12V12H6V10.5ZM6 15H12V16.5H6V15ZM15 12H18V13.5H15V12ZM15 15H18V16.5H15V15ZM1.5 19.5H22.5V21H1.5V19.5Z"
                                  fill="white"
                                />
                                <path
                                  d="M15 9V19.5H19.5V9H15ZM14.25 7.5H20.25C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25V20.25C21 20.4489 20.921 20.6397 20.7803 20.7803C20.6397 20.921 20.4489 21 20.25 21H14.25C14.0511 21 13.8603 20.921 13.7197 20.7803C13.579 20.6397 13.5 20.4489 13.5 20.25V8.25C13.5 8.05109 13.579 7.86032 13.7197 7.71967C13.8603 7.57902 14.0511 7.5 14.25 7.5Z"
                                  fill="white"
                                />
                              </g>
                            </svg>
                            <span>{trans.contact.location_a}</span>
                          </div>
                        </div>
                        <div className="content-margin customRight ndahhqheq">
                          {trans.contact.location_a_text}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12 col-xl-3 ieqwheh1asbdbas col-service">
                  <div
                    className="customMarginTop pt5 customMT86"
                    style={{ marginTop: '155px' }}
                  >
                    <ul
                      className="customPaddingUl"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <li>
                        <div className="icon">
                          <div
                            style={{ color: 'white', display: 'inline-flex' }}
                            className="customMarginBottom"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  d="M4.5 3V19.5H13.5V3H4.5ZM3.75 1.5H14.25C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V20.25C15 20.4489 14.921 20.6397 14.7803 20.7803C14.6397 20.921 14.4489 21 14.25 21H3.75C3.55109 21 3.36032 20.921 3.21967 20.7803C3.07902 20.6397 3 20.4489 3 20.25V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5Z"
                                  fill="white"
                                />
                                <path
                                  d="M6 6H12V7.5H6V6ZM6 10.5H12V12H6V10.5ZM6 15H12V16.5H6V15ZM15 12H18V13.5H15V12ZM15 15H18V16.5H15V15ZM1.5 19.5H22.5V21H1.5V19.5Z"
                                  fill="white"
                                />
                                <path
                                  d="M15 9V19.5H19.5V9H15ZM14.25 7.5H20.25C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25V20.25C21 20.4489 20.921 20.6397 20.7803 20.7803C20.6397 20.921 20.4489 21 20.25 21H14.25C14.0511 21 13.8603 20.921 13.7197 20.7803C13.579 20.6397 13.5 20.4489 13.5 20.25V8.25C13.5 8.05109 13.579 7.86032 13.7197 7.71967C13.8603 7.57902 14.0511 7.5 14.25 7.5Z"
                                  fill="white"
                                />
                              </g>
                            </svg>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              {' '}
                              {trans.contact.location_b}
                            </span>
                          </div>
                        </div>
                        <div className="content-margin ndahhqheq">
                          {trans.contact.location_b_text}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12 col-xl-3 col-service">
                  <div
                    className="customMarginTop pt5 customMT86"
                    style={{ marginTop: '155px' }}
                  >
                    <ul
                      className="customPaddingUl"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <li>
                        <div className="icon">
                          <div
                            style={{ color: 'white', display: 'inline-flex' }}
                            className="customMarginBottom"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  d="M4.5 3V19.5H13.5V3H4.5ZM3.75 1.5H14.25C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V20.25C15 20.4489 14.921 20.6397 14.7803 20.7803C14.6397 20.921 14.4489 21 14.25 21H3.75C3.55109 21 3.36032 20.921 3.21967 20.7803C3.07902 20.6397 3 20.4489 3 20.25V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5Z"
                                  fill="white"
                                />
                                <path
                                  d="M6 6H12V7.5H6V6ZM6 10.5H12V12H6V10.5ZM6 15H12V16.5H6V15ZM15 12H18V13.5H15V12ZM15 15H18V16.5H15V15ZM1.5 19.5H22.5V21H1.5V19.5Z"
                                  fill="white"
                                />
                                <path
                                  d="M15 9V19.5H19.5V9H15ZM14.25 7.5H20.25C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25V20.25C21 20.4489 20.921 20.6397 20.7803 20.7803C20.6397 20.921 20.4489 21 20.25 21H14.25C14.0511 21 13.8603 20.921 13.7197 20.7803C13.579 20.6397 13.5 20.4489 13.5 20.25V8.25C13.5 8.05109 13.579 7.86032 13.7197 7.71967C13.8603 7.57902 14.0511 7.5 14.25 7.5Z"
                                  fill="white"
                                />
                              </g>
                            </svg>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              {' '}
                              {trans.contact.location_c}
                            </span>
                          </div>
                        </div>
                        <div className="content-margin">
                          {trans.contact.location_c_text}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-12 col-xl-4 tcomJapanMB"
                  style={{ display: 'none' }}
                ></div>
              </div>

              <div className="row customRowFooter2 pt-5">
                <div className="update_contact_block_all col-12 col-xl-3">
                  <h3 className="title">{trans.contact_a}</h3>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li>
                      <div className="update_contact_block">
                        <a
                          href="tel:+84 24 3910 2030"
                          className="update_contact_a"
                        >
                          <img src="/assets/images/home/phone_u.svg" alt="" />
                          <span className="">+84 24 3910 2030</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="update_contact_block">
                        <a
                          href="mailto:info@tsoft.vn"
                          className="update_contact_a"
                        >
                          <img src="/assets/images/home/mail_u.svg" alt="" />
                          <span className="">info@tsoft.vn</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-xl-3 customService nnnjkqkq5 col-service">
                  <div className="service text-white">
                    <h3 className="title">{trans.contact.services}</h3>
                  </div>

                  <div className="content">
                    <ul style={{ padding: 0, margin: 0 }}>
                      {service.map((item, index) => {
                        if (index % 3 === 0)
                          return (
                            <li key={index}>
                              <Link
                                href={
                                  item?.step_action?.length > 0
                                    ? `/service/${item?.seo_url}`
                                    : '/service'
                                }
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="col-12 col-xl-3 nnnjkqkq5 col-service">
                  <div className="content pt-5 customContent">
                    <ul style={{ padding: 0, margin: 0 }}>
                      {service.map((item, index) => {
                        if (index % 3 === 1)
                          return (
                            <li key={index}>
                              <Link
                                href={
                                  item?.step_action?.length > 0
                                    ? `/service/${item?.seo_url}`
                                    : '/service'
                                }
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="col-12 col-xl-3 nnnjkqkq5 col-service">
                  <div className="content pt-5 customContent">
                    <ul style={{ padding: 0, margin: 0 }}>
                      {service.map((item, index) => {
                        if (index % 3 === 2)
                          return (
                            <li key={index}>
                              <Link
                                href={
                                  item?.step_action?.length > 0
                                    ? `/service/${item?.seo_url}`
                                    : '/service'
                                }
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-xl-3"></div>
                <div className="col-12 col-xl-3" style={{ marginTop: '50px' }}>
                  <div className="info text-white">
                    <h3 className="title">{trans.contact.introduction}</h3>
                  </div>
                  <div className="content">
                    <ul style={{ padding: 0, margin: 0 }}>
                      <li>
                        <Link href="/service">
                          <a>{trans.contact.href_a}</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/solution">
                          <a>{trans.contact.href_b}</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/project">
                          <a>{trans.contact.href_c}</a>
                        </Link>
                      </li>
                      <li>
                        <Link href={`/offshore`}>
                          <a>{trans.off_shore.text}</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="customRowFooter2-MB d-none pt-5">
                <div className="update_contact_block_all col-12 col-xl-3">
                  <h3 className="title">{trans.contact_a}</h3>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li>
                      <div className="update_contact_block">
                        <a
                          href="tel:+84 24 3910 2030"
                          className="update_contact_a"
                        >
                          <img src="/assets/images/home/phone_u.svg" alt="" />
                          <span className="">+84 24 3910 2030</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="update_contact_block">
                        <a
                          href="mailto:info@tsoft.vn"
                          className="update_contact_a"
                        >
                          <img src="/assets/images/home/mail_u.svg" alt="" />
                          <span className="">info@tsoft.vn</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <div className="col-8 customService">
                    <div className="service text-white">
                      <h3 className="title">{trans.contact.services}</h3>
                    </div>
                    <div className="content">
                      <ul style={{ padding: 0, margin: 0 }}>
                        {service.map((item, index) => {
                          return (
                            <li key={index} className="u21uwfsnfsnvnnewnfwne">
                              <Link
                                href={
                                  item?.step_action?.length > 0
                                    ? `/service/${item?.seo_url}`
                                    : '/service'
                                }
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-4 customService menuFooterMB">
                    <div className="mainMenuFooter text-white">
                      <h3 className="title">MENU</h3>
                    </div>
                    <div className="content" style={{ marginTop: '-3px' }}>
                      <ul style={{ padding: 0, margin: 0 }}>
                        <li className="u21uwfsnfsnvnnewnfwne">
                          <Link href="/service">
                            <a>{trans.contact.href_a}</a>
                          </Link>
                        </li>
                        <li className="u21uwfsnfsnvnnewnfwne">
                          <Link href="/solution">
                            <a>{trans.contact.href_b}</a>
                          </Link>
                        </li>
                        <li className="u21uwfsnfsnvnnewnfwne">
                          <Link href="/project">
                            <a>{trans.contact.href_c}</a>
                          </Link>
                        </li>
                        <li className="u21uwfsnfsnvnnewnfwne">
                          <Link href={`/offshore`}>
                            <a>{trans.off_shore.text}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="copyright-left">
              <img src="/assets/img/slider/power.svg" alt="" />
            </div>
            <div className="copyright-right">
              <p>Copyright © 2023 TCOM Software. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(Layout);
